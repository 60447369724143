<app-header [Tit]='nameItem'></app-header>
<img src="assets/loading.gif" [hidden]="load" class="loader" >
<div class="container-fluid">
  <div class="row">
    <div class="col-9 small">
      <h3 style="color:#21618C;">{{txtnameFarm}}</h3>
    </div>
    <div class="col-3">
      <button type="button" class="btn btn-primary btn-sm" style="font-size:x-small;" (click)="contentshare(0)" [hidden]="hidecontent2">Regresar</button>
    </div>
  </div>
</div>
<div class="container-fluid" [hidden]="hidecontent1">
  <div class="chart-container card card-body border-info" id="graf2" style="height:auto">

    <div class="row">
      <div class="col-12 small">
        <p class="small text-primary">Estado de las unidades al dia: {{fechastr}}</p>
      </div>
    </div>
    <div class="row p-1">
      <div class="col-4">
        <div class="card p-0 border border-secondary border-2 rounded h-100"  [ngClass]="{'border border-info border-2 ' : (statusUM1) === 'OK'||(statusUM1) === 'Presentó fallas'}">
          <button class="card-body border border-white" [disabled]="disUM1" (click)="contentshare(1)">
            <h6 class="small badge bg-primary text-wrap" >Unidad Motriz 1</h6>
            <p class="small text-wrap" [ngStyle]="{color: (statusUM1) == 'OK' ? '#00aa00' : '#ff0000'}">{{statusUM1}}</p>
          </button>
        </div>
      </div>
      <div class="col-4">
        <div class="card p-0 border border-secondary border-2 rounded h-100"  [ngClass]="{'border border-info border-2 ' : (statusUM2) === 'OK' ||(statusUM2) === 'Presentó fallas'}">
          <button class="card-body border border-white" [disabled]="disUM2" (click)="contentshare(2)">
            <h6 class="small badge bg-primary text-wrap" >Unidad Motriz 2</h6>
            <p class="small text-wrap" [ngStyle]="{color: (statusUM2) == 'OK' ? '#00aa00' : '#ff0000'}">{{statusUM2}}</p>
          </button>
        </div>
      </div>
      <div class="col-4">
        <div class="card p-0 border border-secondary border-2 rounded h-100"  [ngClass]="{'border border-info border-2 ' : (statusUM3) === 'OK' ||(statusUM3) === 'Presentó fallas'}">
          <button class="card-body border border-white" [disabled]="true" (click)="contentshare(3)">
            <h6 class="small badge bg-primary text-wrap">Unidad Motriz 3</h6>
            <p class="small text-wrap" [ngStyle]="{color: (statusUM3) == 'OK' ? '#00aa00' : '#ff0000'}">{{statusUM3}}</p>
          </button>
        </div>
      </div>

    </div>
    <div class="row p-1">
      <div class="col-4">
        <div class="card p-0 border border-secondary border-2 rounded h-100"  [ngClass]="{'border border-info border-2 ' : (statusUM4) === 'OK' ||(statusUM4) === 'Presentó fallas'}">
          <button class="card-body border border-white" [disabled]="true" (click)="contentshare(4)">
            <h6 class="small badge bg-primary text-wrap">Unidad Motriz 4</h6>
            <p class="small text-wrap" [ngStyle]="{color: (statusUM4) == 'OK' ? '#00aa00' : '#ff0000'}">{{statusUM4}}</p>
          </button>
        </div>
      </div>
      <div class="col-4">
        <div class="card p-0 border border-secondary border-2 rounded h-100"  [ngClass]="{'border border-info border-2 ' : (statusUM5) === 'OK' ||(statusUM5) === 'Presentó fallas'}">
          <button class="card-body border border-white" [disabled]="true" (click)="contentshare(5)">
            <h6 class="small badge bg-primary text-wrap">Unidad Motriz 5</h6>
            <p class="small text-wrap" [ngStyle]="{color: (statusUM5) == 'OK' ? '#00aa00' : '#ff0000'}">{{statusUM5}}</p>
          </button>
        </div>
      </div>
      <div class="col-4">
        <div class="card p-0 border border-secondary border-2 rounded h-100"  [ngClass]="{'border border-info border-2 ' : (statusUM6) === 'OK' ||(statusUM6) === 'Presentó fallas'}">
          <button class="card-body border border-white" [disabled]="true" (click)="contentshare(6)">
            <h6 class="small badge bg-primary text-wrap">Unidad Motriz 6</h6>
            <p class="small text-wrap" [ngStyle]="{color: (statusUM6) == 'OK' ? '#00aa00' : '#ff0000'}">{{statusUM6}}</p>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid small" [hidden]="hidecontent2">


  <div class="chart-container card card-body border-info" id="graf2" style="height:auto">

  <div class="row small">
    <div class="col-6 small">
      <div class="row">
        <div class="col-6">
          <h6>Unidad Motriz:</h6>
        </div>
        <div class="col-6">
          <div class="btn-group dropstart">
           <select name="first" [(ngModel)]="selectUM" #t (change)="UMSelect(t.value)" class="btn btn-secondary dropdown-toggle" style="background-color: white; color:#21618C; margin-top: 0; margin-bottom: 0.5rem; font-weight: 500; line-height: 1.2;font-size: 1rem; font-size:x-small;">
             <option *ngFor="let title of listUM" [value]="title.Value" class="btn btn-secondary dropdown-toggle " style="background-color: white; color:#21618C; font-size:x-small;">
               {{title.Text}}
             </option>
         </select>
        </div>
       </div>
      </div>
    </div>
    <div class="col-6 small">
      <h6 [ngStyle]="{color: (txtFalla) == 'Correcto' ? '#00aa00' : '#ff0000'}">{{txtFalla}}: {{txtActiva}}</h6>
    </div>
   </div>
   <div class="row small pb-1">
     <div class="col-3 small"><label>Presion</label></div>
     <div class="col-7 small"><label [ngStyle]="{color: (txtPresion) != 'OK' ? '#ff0000' : '#00aa00'}" >{{txtPresion}}</label><label>{{txtPresion2}}</label></div>
     <div class="col-2"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(2,'Presion')" style="font-size:x-small;">+</button></div>
   </div>

   <div class="row small pb-1">
     <div class="col-3 small"><label>Fuerza</label></div>
     <div class="col-7 small"><label [ngStyle]="{color: (txtFuerza) != 'OK' ? '#ff0000' : '#00aa00'}">{{txtFuerza}}</label><label>{{txtFuerza2}}</label></div>
     <div class="col-2"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(1,'Fuerza')" style="font-size:x-small;">+</button></div>
   </div>
   <div class="row small pb-1">
     <div class="col-3 small"><label>Corriente</label></div>
     <div class="col-7 small"><label [ngStyle]="{color: (txtCorriente) != 'OK' ? '#ff0000' : '#00aa00'}">{{txtCorriente}}</label><label>{{txtCorriente21}}</label></div>
     <div class="col-2"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(3,'Corriente')" style="font-size:x-small;">+</button></div>
   </div>
   <div class="row small pb-1">
     <div class="col-3 small"><label>Corriente 2</label></div>
     <div class="col-7 small"><label [ngStyle]="{color: (txtCorriente2) != 'OK' ? '#ff0000' : '#00aa00'}">{{txtCorriente2}}</label><label>{{txtCorriente22}}</label></div>
     <div class="col-2"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(4,'Corriente2')" style="font-size:x-small;">+</button></div>
   </div>
   <div class="row small pb-1">
     <div class="col-3 small"><label>{{txtnumRueda}}</label></div>
     <div class="col-7 small"><label [ngStyle]="{color: (txtRueda) != 'OK' ? '#ff0000' : '#00aa00'}" style="font-size:x-small;">{{txtRueda}}</label></div>
     <div class="col-2"></div>
   </div>
   <div class="row small pb-1">
     <div class="col-3 small"><label>{{txtnumGuarda}}</label></div>
     <div class="col-7 small"><label [ngStyle]="{color: (txtGuarda) != 'OK'? '#ff0000' : '#00aa00'}" style="font-size:x-small;">{{txtGuarda}}</label></div>
     <div class="col-2"></div>
   </div>
   <div class="row small pb-1">
     <div class="col-md-6 col-sm-6"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(0,'')" style="font-size:x-small;">General</button></div>
   </div>
  </div>
</div>

<div class="container-fluid" [hidden]="hidecontent2">
  <div class="row pb-2 pt-2 small" >
    <div class="col-auto small">
      <input [formControl]="dateStringControl" class="form-control small" type="date" id="date-string" (change)="updateDate($event)" style="font-size:x-small;"/>
     </div>
     <div class="col-2 small">
      <input [ngxTimepicker]='picker' [formControl]="timeStringControl" class="form-control small" placeholder="Hora Inicio" style="font-size:x-small;">
      <ngx-material-timepicker #picker></ngx-material-timepicker>
     </div>
     <div class="col-2 small">
      <input [ngxTimepicker]='picker2' [formControl]="timeStringControl2" class="form-control small" placeholder="Hora Fin" style="font-size:x-small;">
      <ngx-material-timepicker #picker2></ngx-material-timepicker>
     </div>
     <div class="col-auto">
      <button type="button" class="btn btn-primary btn-sm" (click)="grafShare()" style="font-size:x-small;">Cargar</button>
     </div>
  </div>

  <div class="row">
    <div class="col-12">
          <div class="chart-container card card-body border-info" id="graf2" style="height:auto">
            <canvas id="realtime" width="1190" height="600"></canvas>
            <div class="card-content">
              <div class="row small">
                <div class="col-2 small"><label class="small">{{titval}}</label></div>
                <div class="col-2 small"><label class="small"></label></div>
                <div class="col-6 small" [hidden]="hideminmax"><label class="small">{{minmaxval}}</label></div>
                <div class="col-2"> <button type="button" class="btn btn-primary btn-sm" style="font-size:x-small;" (click)="contentshare(10)">Historial</button></div>
              </div>
          </div>
        </div>
    </div>
</div>

</div>

<div class="container-fluid small" [hidden]="hidecontent3">

    <div class="row">
      <div class="col-9">
        <label class="small text-primary">Historial a 3 meses</label>
      </div>
      <div class="col-3">
        <button type="button" class="btn btn-primary btn-sm" style="font-size:x-small;" (click)="contentshare(12)">Regresar</button>
      </div>    </div>
    <div class="card card-body border-info small p-0" id="tableData1" style="height:600px">
      <div class="scrollbar scrollbar-primary p-0" style="width:auto; height:auto">
<div class="overflow-hidden small p-0">
<div class="small">

    <table class="table table-striped table-hover" id="mytable" [hidden]="FailT1">
      <label [hidden]="hideFallas" class="text-primary">No hay Fallas.</label>
      <thead>
        <tr>
          <th name='inicio'>Fecha y Hora de la Falla</th>
          <th name='falla'>Falla</th>
          <th>Unidad Motriz</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let dat of fails; let i=index">
          <td>{{ dat.Date }}</td>
          <td>{{ dat.FailType }}</td>
          <td>{{ dat.NumUM }}</td>
        </tr>

      </tbody>
    </table>

    <table class="table table-striped table-hover" id="mytable1" [hidden]="FailT2">
      <label [hidden]="hideFallas" class="text-primary">No hay Fallas.</label>
      <thead>
        <tr>
          <th>Hora y Fecha de la Falla</th>
          <th name='falla'>Falla</th>
          <th>Unidad Motriz</th>

        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let dat of fails1; let i=index" >
          <td>{{dat.Date}}</td>
          <td>{{ dat.Fail }}</td>
          <td>{{ dat.UnidadM }}</td>
        </tr>

      </tbody>
    </table>


  </div>
</div>
</div>

    </div>


</div>
<app-footer></app-footer>
