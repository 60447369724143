import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{Router, Params,ParamMap}from '@angular/router'

import{Chart}from 'chart.js'
import { umData } from '../../models/modelsUM/dataUM';
import{ApiUMService}from '../../servicios/apiUM.service';
import { Observable, Subject, timer } from 'rxjs'
import { FormControl } from '@angular/forms';
import { formatNumber, Time } from '@angular/common';
import { paramsUM } from 'src/app/models/modelsUM/paramsUM';
import { failsUM } from 'src/app/models/modelsUM/failsUM';
import { failsTable } from 'src/app/models/modelsUM/failsTable';
import { ParsedEvent } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import {timeOut} from 'src/app/models/modelsUM/timeOut'
import { timeOutTable } from 'src/app/models/modelsUM/timeOutTable';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { ValueAndText } from 'src/app/models/ValueAndText';
import { valueUM } from 'src/app/models/valueUm';
import * as moment from 'moment';
import {failsTable1} from '../../models/modelsUM/failsTable1'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



@Component({
  selector: 'app-unidadesmotrices',
  templateUrl: './unidadesmotrices.component.html',
  styleUrls: ['./unidadesmotrices.component.css']
})
export class UnidadesmotricesComponent {

  /**
	* Interval to update the chart
	* @var {any} intervalUpdate
	*/
	private intervalUpdate: any = null;
	/**
	* The ChartJS Object
	* @var {any} chart
	*/

  public code0:string="";
  public code1:string="";
  public des0:string="";
  public txtMinI:any=0;
  public txtMaxI:any=0;
  public ScaleRight="";
  public ScaleLeft="";
  public alertHide=true;
  public load=false;
  public FailT1=true;
  public FailT2=true;
  public contfilltable:number=0;

  public hideminmax=true;

  public EventsT=false;
  public min0:any=0;
  public max0:any=0;
  public index0:number=0;
  public dateEv:any="";
  public hideFallas=true;
  public selectUM:any='1';
  public DateParos:string='';
  verSeleccion: string = '';
	public chart: any = null;
  public var:any=null;
  public num: number=0;
  public hour:any=null;
  public valfuerza: any = null;
  public valpresion: any = null;
  public valcorriente: any = null;
  public valcorriente2: any = null;
  public farmidg:any=0;
  public hourg:string="-1";
  public datesg:number=-1;
  public datefg:number=-1;
  public dateg:number=0;
  public username:string="";
  public valor1:any="";
  public titval:any="";
  date = new Date();
  nameindex:string="Establo ";
  public listUM:ValueAndText[]=[];
  dateStringControl = new FormControl('');
  timeStringControl = new FormControl('');
  timeStringControl2 = new FormControl('');
 // dateStringControl1 = new FormControl('');
  public fechastr:string="";
  public numval:number=0;
  public fechane:Date= new Date;
  public time:any="";
  public time2:any="";
  public minmaxval:string="";
 // public paramsUM1:any="";
  public minval:any="";
  public maxval:any="";
  public nomUM="1";
  fails: failsTable1[] = [];
  fails1: failsTable[] = [];
  cowsStuck: timeOutTable[] = [];
  public txtFuerza:any="";
  public txtPresion:any="";
  public txtCorriente:any="";
  public txtFuerza2:any=": ";
  public txtPresion2:any=": ";
  public txtCorriente2:any=": ";
  public txtCorriente21:any=": ";
  public txtCorriente22:any=": ";
  activate: Boolean = false;
  valUM:any=null;
  public txtFalla:string="";
  public nameItem="";
  //public load=false;
  public tableTO=true;
  public txtGuarda="";
  public txtRueda="";
  public txtnumGuarda="Guardamotor";
  public txtnumRueda="Rueda";
  public txtnameFarm:any="";
  public numUM:string="1";
  public dataUM:umData[]=[];

  public datemes:any;

  constructor(private http: HttpClient,public api:ApiUMService, private router:Router) {
  //setTimeout(() => this.activate = true, 3000);

  let list:any="";
  this.nameItem="- Unidades Motrices"
  this.txtFalla="Correcto";
  this.txtFuerza="OK";
  this.txtPresion="OK";
  this.txtCorriente="OK";
  this.txtCorriente2="OK";
  this.txtRueda="OK";
   this.txtGuarda="OK";
  this.numUM="1";
  //this.load=false;

  let datosUser = JSON.parse(localStorage.getItem('usuario') || '0');

  if(datosUser!=null&&datosUser!=0)
  {
    list=datosUser.UserFarm;
    this.username=datosUser.UserName;
    this.valor1=this.username;
    this.txtnameFarm = localStorage.getItem('nameFarm');
    this.farmidg = localStorage.getItem('farmID');
    var fecha = new Date(); //Fecha actual
    var mes = fecha.getMonth()+1; //obteniendo mes
    var dia = fecha.getDate(); //obteniendo dia
    var ano = fecha.getFullYear(); //obteniendo año
    var diastr="";
    var messtr="";
   // this.dateEv = new Date();
    //var dateEvnew = new Date();
    //var dateSt:any="";


   // dateEvnew.setDate(dateEvnew.getDate() - 1);
   // var date=moment(dateEvnew).format('LL');;

    //this.dateEv=date;

    if(dia<10)
    {
      diastr='0'+dia;
    }
    else
    {
      diastr=dia.toString();
    }
    if(mes<10)
    {
      messtr='0'+mes
    }
    else
    {
      messtr=mes.toString();
    }
      this.fechastr=ano+"-"+messtr+"-"+diastr;
      this.dateStringControl = new FormControl(this.fechastr);

      this.fechastr=this.dateStringControl.value;


  }
  else
  {
    this.router.navigate(['login']);
  }
}
	/**
	* On component initialization
	* @function ngOnInit
	* @return {void}
	*/

	private ngOnInit(num1:number): void {

    var date = new Date();
    var time1 = moment.duration("03:00:00");
    var date1 = moment(date)
    date1.subtract(time1);
    var cf=date1.format();
    //date1=date1._d;



    this.hideFallas=true;
    if(this.listUM.length==0)
    {
      this.FillSelectUM();
    }

    this.CreateChart();



   if(num1==undefined)
   {
    var params = JSON.parse(localStorage.getItem('paramsUM') || '{}');
    if(params.length==0||params.length==undefined)
    {
     this.getParams();
    }

    this.fechane=this.dateStringControl.value;
    var f = moment(this.fechane);
    var fechaEv=moment(this.fechane);
    var dfEv=Date.parse(fechaEv.toString());
    var dfEv1=Date.parse(fechaEv.toString());
    //var datestr=moment(dfEv).format('LL');
    var datemes=Date.parse(fechaEv.toString());

    var datemesn=moment(datemes).subtract(3, 'months').format('YYYY-MM-DD');
    this.datemes=Date.parse(datemesn.toString());

    var datestr1=moment(dfEv1).subtract(1).format('LL');
    this.dateEv=datestr1;

    var dates_as_int = Date.parse(f.toString());
    var datenew=dates_as_int.toString();

    datenew=datenew.substring(0,10);
    let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 1;
    let suma =dates_as_int + semanaEnMilisegundos;
    //let fechaDentroDeUnaSemana = new Date(suma);


    //var newde=fechaDentroDeUnaSemana.toString();
    //var dates_as_intf = Date.parse(newde);
    this.showData(0,dates_as_int,suma, cf,dfEv);

   }

    //this.intervalUpdate = setInterval(this.showData.bind(this), 150000);
	}

  CreateChart(){
		this.chart = new Chart('realtime', {
			type: 'line',
			data: {
				labels: [],
				datasets: [{
          label: 'Fuerza',
          fill: false,
          data: [],
          backgroundColor: '#1AAA08',
          borderColor: '#1AAA08',
          borderWidth : 3,
          pointRadius:0,
          },
          {
            label: 'Presion',
            fill: false,
            data: [],
            backgroundColor: '#0A4AB6',
            borderColor: '#0A4AB6',
            borderWidth : 3,
           pointRadius:0,
            },
            {
              label: 'Corriente',
              fill: false,
              data: [],
              backgroundColor: '#D35400',
              borderColor: '#D35400',
              borderWidth : 3,
              pointRadius:0,
            },
            {
              label: 'Corriente2',
              fill: false,
              data: [],
              backgroundColor: '#F5B041',
              borderColor: '#F5B041',
              borderWidth : 3,
              pointRadius:0,
            }


				]
			  },
			  options: {
          responsive:true,
          maintainAspectRatio:true,
				tooltips: {
					enabled: true,
          intersect: true
				},
        hover:{mode: 'index', intersect: false,},
				legend: {
					display: true,
					position: 'bottom',
					labels: {
						fontColor: '#00528b',
            boxWidth: 8,
            fontSize:10,
					},


				},
				scales: {
				  yAxes: [{
					  ticks: {
						  fontColor: '#168ede',
              fontSize:10
					  },

				  }]
          ,
				  xAxes: [{type: 'time',
          time: {
            unit: 'hour'
        },
        distribution: 'linear',
         ticks: {
          autoSkipPadding: 5,
          fontColor: '#168ede',
          maxTicksLimit:10,
          padding:0,
        fontSize:9,}}]
				},
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy'
            },
            zoom: {
              enabled: true,
              mode: 'xy'
            }
          }
        }
			  }
		});
  }

  public FillSelectUM():void{
    var cont=0;
    this.api.getNumUm(this.farmidg).subscribe(response => {

      let dataResponse:valueUM[]=response;


			if(response.error === undefined) {

        dataResponse.forEach(element => {
          this.listUM.push({Value:element.Numero,Text:element.Numero});
          if(cont==0)
          {
            this.selectUM=element.Numero;
          }
          cont=1;

        });
			} else {
				console.error("ERROR: The response had an error, retrying");
			}

		}, error => {
			console.error("ERROR: Unexpected response");
		});

  }


	/**
	* On component destroy
	* @function ngOnDestroy


	* @return {void}
	*/
	private ngOnDestroy(): void {
	clearInterval(this.intervalUpdate);
    // this.dtTrigger.unsubscribe();

	}


  	/**
	* Print the data to the chart
	* @function getParams
	* @return {void}
	*/

   private getParams():void{
    this.api.getFromParams(this.farmidg).subscribe(response => {
      let dataResponse:paramsUM[]=response;
			if(response.error === undefined) {
      localStorage.setItem('paramsUM', JSON.stringify(dataResponse));
			} else {
				console.error("ERROR: The response had an error, retrying");
			}

		}, error => {
			console.error("ERROR: Unexpected response");
		});
   }
	/**
	* Print the data to the chart
	* @function showData
	* @return {void}
	*/
  private showData(num1:number, dateStart:number, dateFin:number,date1:any, dateEv:any): void {


    this.numval=num1;
    var num:number;


    if(num1==0)
    {
      this.titval="General";
      this.hideminmax=true;
    }
    else if(num1==1)
    {
      this.titval="Fuerza";
      this.hideminmax=false;
    }
    else if(num1==2)
    {
      this.titval="Presion";
      this.hideminmax=false;
    }
    else if(num1==3)
    {
      this.titval="Corriente";
      this.hideminmax=false;
    }
    else if(num1==4)
    {
      this.titval="Corriente2";
      this.hideminmax=false;
    }

    var fallaRueda1=0;
    var fallaRueda2=0;
    var fallaguarda1=0;
    var fallaguarda2=0;
    var fallaFuerza=0;
    var fallaPresion=0;
    var fallaCorriente=0;
    var fallaCorriente2=0;
    var conttable=0;

    var umNum="UM"+this.selectUM;

    if(this.farmidg==61)
    {
      this.EventsT=true;
      this.FailT2=false;
      this.FailT1=true;
      this.api.getFailsUM(this.datemes,dateFin,this.farmidg,num1).subscribe(response => {
        let dataResponse:any[]=response;

        if(dataResponse.length==0)
        {
          this.hideFallas=false;
        }
        conttable=dataResponse.length;


        var countFails:number=0;

        var falla:string="";

        var valfalla:number=0;
        var valfalla2:number=0;
        var valfalla3:number=0;
        var valfalla4:number=0;




        var contfail=0;




        if(response.error === undefined) {
          countFails=dataResponse.length;
          dataResponse.forEach(element => {
           // prueba=Number(submin)

            //let list;

            conttable=conttable-1;
            falla=element.Type
            if((falla==element.Type||falla=="") && moment.utc(element.Date).format('YYYY-MM-DD')== moment(this.fechane).format('YYYY-MM-DD'))
            {

               if(falla=="F_Fuerza")
               {
                valfalla=1;
               }
               else if(falla=="F_Presion")
               {
                valfalla2=1;
               }
               else if(falla=="F_Corriente")
               {
                valfalla3=1;
               }
               else if(falla=="F_Corriente2")
               {
                valfalla4=1;
               }




              contfail++;



            }
            var nomfalla="";
            if(falla=="F_Corriente")
            {
                nomfalla="Corriente";
            }
            else if(falla=="F_Corriente2")
            {
              nomfalla="Corriente2";
            }
            else if(falla=="F_Fuerza")
            {
              nomfalla="Fuerza";
            }
            else if(falla=="F_Presion")
            {
              nomfalla="Presion";
            }

            if(this.contfilltable==0)
            {
              this.fails1.push({ Date:moment.utc(element.Date).format('YYYY-MM-DD HH:mm:ss'),Fail: nomfalla, UnidadM: "1"});
            }

         //  falla=element.Type;
         if(conttable==0)
         {
           this.contfilltable=1;
         }

          });

          if(valfalla==1)
          {
            this.txtFuerza="Falla";
          }
          if(valfalla2==1)
          {
            this.txtPresion="Falla";
          }
          if(valfalla3==1)
          {
            this.txtCorriente="Falla";
          }
          if(valfalla4==1)
          {
            this.txtCorriente2="Falla";
          }
          if(valfalla!=0||valfalla2!=0||valfalla3!=0||valfalla4!=0)
          {
            this.txtFalla="Presentó fallas";
          }


          if( this.fails1.length>0)
          {
            this.hideFallas=true;
          }
          else{
            this.hideFallas=false;
          }
         // this.fails1 = (datanew as any);
        } });
    }
    else
    {
      this.EventsT=false;
      this.FailT1=false;
      this.FailT2=true;

      var numrueda=0;
      var numguarda=0;
      var numrueda2=0;
      var numguarda2=0;

      this.api.getFailsTable(this.datemes,dateFin,this.farmidg).subscribe(response => {
        let dataresponse:failsTable1[]=response;
       if(dataresponse.length>0)
       {
         conttable=dataresponse.length;

         //this.txtFalla= "Presentó fallas";
         this.hideFallas=true;

         dataresponse.forEach(element => {
          conttable=conttable-1;



         if(element.NumUM==umNum )
         {
          if(moment.utc(element.Date).format('YYYY-MM-DD')== moment(this.fechane).format('YYYY-MM-DD'))
           {
             this.txtFalla= "Presentó fallas";
             this.hideFallas=true;
             if(element.FailType=="FALLA EN LA RUEDA 1"||element.FailType=="FALLA EN LA RUEDA 2")
             {
               if(element.FailType=="FALLA EN LA RUEDA 1"){
                numrueda=1;
               }
               else{
                numrueda2=1;
               }
               this.txtRueda= moment.utc(element.Date).format("DD/MM/YYYY HH:mm:ss") +" : "+ element.FailType;

             }

             if(element.FailType=="FALLA EN EL GUARDAMOTOR 1"||element.FailType=="FALLA EN EL GUARDAMOTOR 2")
             {
               this.txtGuarda=moment.utc(element.Date).format("DD/MM/YYYY HH:mm:ss") +" : "+ element.FailType;
               if(element.FailType=="FALLA EN EL GUARDAMOTOR 1"){
                numguarda=1;
               }
               else{
                numguarda2=1;
               }

             }

             if(element.FailType=="FUERZA EXCEDIDA"||element.FailType=="FUERZA BAJA")
             {
               this.txtFuerza=moment.utc(element.Date).format("DD/MM/YYYY HH:mm:ss") +" : "+ element.FailType;

             }

             if(element.FailType=="PRESION EXCEDIDA"||element.FailType=="PRESION BAJA")
             {
               this.txtPresion=moment.utc(element.Date).format("DD/MM/YYYY HH:mm:ss") +" : "+ element.FailType;

             }

             if(element.FailType=="AMPERAJE ALTO M1"||element.FailType=="AMPERAJE BAJO M1")
             {
               this.txtCorriente=moment.utc(element.Date).format("DD/MM/YYYY HH:mm:ss") +" : "+ element.FailType;

             }

             if(element.FailType=="AMPERAJE ALTO M2"||element.FailType=="AMPERAJE BAJO M2")
             {
               this.txtCorriente2=moment.utc(element.Date).format("DD/MM/YYYY HH:mm:ss") +" : "+ element.FailType;

             }

           }

             if(this.contfilltable==0)
             {

               var date =moment.utc(element.Date).format("YYYY-MM-DD HH:mm:ss");
               this.fails.push({Date:date,NumUM:element.NumUM,FailType:element.FailType});

             }

         }
         if(conttable==0)
         {
           this.contfilltable=1;
         }

        });

         if(numguarda!=0&&numguarda2==0)
         {
           this.txtnumGuarda="Guardamotor1";
         }
         else if(numguarda2!=0&&numguarda==0)
         {
           this.txtnumGuarda="Guardamotor2";
         }
         else if(numguarda!=0&&numguarda2!=0)
         {
           this.txtnumGuarda="Guardamotor1 y Guardamotor2";
         }
         else{
           this.txtGuarda="OK";
         }

         if(numrueda!=0&&numrueda2==0)
         {
           this.txtnumRueda="Rueda1";
         }
         else if(numrueda2!=0&&numrueda==0)
         {
           this.txtnumRueda="Rueda2";
         }
         else if(numrueda!=0&&numrueda2!=0)
         {
           this.txtnumRueda="Rueda1 y Rueda2";
         }
         else{
           this.txtRueda="OK";
         }


       }
       else
       {
         this.hideFallas=false;
       }

     });
    }


    //this.SpinnerService.show();
    this.load=false;
    var numUMSel=this.selectUM;
    if(numUMSel=="")
    {
      numUMSel="1";
    }
		this.api.getFromAPI(dateStart,dateFin,this.farmidg,numUMSel).subscribe(response => {

       this.dataUM=response;


			if(response.error === undefined) {

     // console.log(date1);



    var arrPorID:umData[]=[];
     num=this.dataUM.length;
     var valpresion="";
     var valfuerza="";
     var valcorriente="";
     var valcorriente2="";
     var valRueda="";
     var valGuarda="";

        if(num1==0)
        {
          function filtrarPorID(obj:umData)
          {
            if ('Date' in obj && obj.Date >= date1) {
              return true;
            } else {
              return false;
            }
          }
           arrPorID = this.dataUM.filter(filtrarPorID);
         // console.log( arrPorID);



          arrPorID.forEach(element => {

            valpresion=element.Presion.toString();
            valfuerza=element.Fuerza.toString();
            valcorriente=element.Corriente1.toString();
            valcorriente2=element.Corriente2.toString();
            //valRueda=element.Rueda1.toString();
          //0 valGuarda=element.Guardamotor1.toString();


            // this.hour=element.Date;
            var dateNew=moment.utc(element.Date).format("YYYY-MM-DD HH:mm:ss");
             this.chart.data.labels.push(dateNew);

             if(this.farmidg==61)
             {
              if(element.Guardamotor1!="OK"&&element.Guardamotor2=="OK")
              {
                this.txtGuarda=element.Guardamotor1;
                this.txtnumGuarda="Guardamotor1";
              }
              else if(element.Guardamotor2!="OK"&&element.Guardamotor1=="OK")
              {
                this.txtGuarda=element.Guardamotor2;
                this.txtnumGuarda="Guardamotor2";
              }
              else if(element.Guardamotor1!="OK"&&element.Guardamotor2!="OK")
              {
                this.txtGuarda="Guardamotor1:"+element.Guardamotor1+" Guardamotor2:"+element.Guardamotor2;
                this.txtnumGuarda="";
              }
              else{
                this.txtGuarda="OK";
              }

              if(element.Rueda1!="OK"&&element.Rueda2=="OK")
              {
                this.txtRueda=element.Rueda1;
                this.txtnumRueda="Rueda1";
              }
              else if(element.Rueda2!="OK"&&element.Rueda1=="OK")
              {
                this.txtRueda=element.Rueda2;
                this.txtnumRueda="Rueda2";
              }
              else if(element.Rueda1!="OK"&&element.Rueda2!="OK")
              {
                this.txtnumRueda="Rueda1:"+element.Rueda1+" Rueda2:"+element.Rueda2;
                this.txtRueda="";
              }
              else{
                //this.txtnumRueda="Rueda1:"+element.Rueda1+" Rueda2:"+element.Rueda2;
                this.txtRueda="OK";
              }
             }



             this.chart.data.datasets[0].data.push(element.Fuerza);
             this.chart.data.datasets[1].data.push(element.Presion);
             this.chart.data.datasets[2].data.push(element.Corriente1);
             this.chart.data.datasets[3].data.push(element.Corriente2);

             this.chart.update();


         });

         if(this.txtFuerza2==": ")
         {
          this.txtPresion2=this.txtPresion2+valpresion;
          this.txtFuerza2=this.txtFuerza2+valfuerza;
          this.txtCorriente21=this.txtCorriente21+valcorriente;
          this.txtCorriente22=this.txtCorriente22+valcorriente2;
         }


        }
        else
        {
          this.UpdateChart2(this.dataUM,this.titval,num1);
        }

			}
      //this.SpinnerService.hide();
      this.load=true;
    });


    this.api.getEventsUM(dateEv,this.farmidg).subscribe(response => {
      let dataResponse:timeOut[]=response;
      let table:timeOutTable[]=[];
      let table2:timeOutTable[]=[];
      var countFails:number=0;
      var DateNew="";
      var DateNew2="";
      var subhour2="";
      var subhour="";
      var numvis=0;



      if(response.error === undefined) {
        countFails=dataResponse.length;
        dataResponse.forEach(element => {

          var tf=element.TimeOutFirstYield;
          var ts=element.TimeOutSecondYield;
          var tt=element.TimeOutThirdYield;

          //subhour=DateNew.substring(11,19);
          //subhour2=DateNew2.substring(11,19);

          table.push({Evento: "Tiempo de Plataforma Detenida", Ordena1: tf,Ordena2:ts,Ordena3:tt});
          table2.push({Evento: "Numero de Paros de Plataforma", Ordena1: element.StrikerNumber1.toString(),Ordena2:element.StrikerNumber2.toString(),Ordena3:element.StrikerNumber3.toString()});
          numvis=1;

        });

        table=table.concat(table2);
        this.cowsStuck = (table as any);

      }
      if(numvis==1)
      {
        this.tableTO=false;
      }
      else
      {
        this.tableTO=true;
      }

    });


	}

  updateDate(event: any) {

    //this.fails=[];
    this.cowsStuck=[];
   this.txtFalla="Correcto";
   this.txtFuerza="OK";
   this.txtPresion="OK";
   this.txtCorriente="OK";
   this.txtCorriente2="OK";
   this.txtRueda="OK";
   this.txtGuarda="OK";
   this.timeStringControl = new FormControl("");
   this.timeStringControl2 = new FormControl("");

   this.fechane=this.dateStringControl.value;
   var f = moment(this.fechane);
   var fechaEv=moment(this.fechane);
   var fdEv= Date.parse(fechaEv.toString());

   //var datestr=moment(fdEv).format('LL');
   //this.dateEv=datestr;

   var dfEv1=Date.parse(fechaEv.toString());
   //var datestr=moment(dfEv).format('LL');

   var datestr1=moment(dfEv1).subtract(1).format('LL');
   this.dateEv=datestr1;

   var dates_as_int = Date.parse(f.toString());
   var datenew=dates_as_int.toString();

   datenew=datenew.substring(0,10);
   let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 1;
   let suma =dates_as_int + semanaEnMilisegundos;
   let fechaDentroDeUnaSemana = new Date(suma);


   var newde=fechaDentroDeUnaSemana.toString();
   var dates_as_intf = Date.parse(newde);

    this.chart.clear();
    this.chart.destroy();

    //this.ngOnInit(0);
    this.CreateChart();

   // var date = new Date();
   // var time1 = moment.duration("03:00:00");
    //var date1 = moment(dates_as_intf)
    //date1.subtract(time1);

   // var date = new Date();

   var date = new Date();
   var dateform=moment(date).format("YYYY-MM-DD");

   var time1;
   var date1;;
   var cf:any;

   if(this.fechane.toString()==dateform)
   {
      time1 = moment.duration("03:00:00");
      date1 = moment(date)
      date1.subtract(time1);
      cf=date1.format();
   }
   else
   {
     time1 = moment.duration("03:00:00");
     date1 = moment(suma)
     date1.subtract(time1);
     cf=date1.format();
   }

   // console.log(dates_as_intf);
    //console.log(date1);

    this.showData(0,dates_as_int,dates_as_intf,cf,fdEv);

  }
  grafShare() {

   // this.txtFalla="Correcto";
    //this.txtFuerza="Correcto";
    //this.txtPresion="Correcto";
    //this.txtCorriente="Correcto";
    //this.txtCorriente2="Correcto";
   // var params = JSON.parse(localStorage.getItem('paramsUM') || '{}');
    //if(params.length==0||params.length==undefined)
    //{
     //this.getParams();
     //params = JSON.parse(localStorage.getItem('paramsUM') || '{}');
   // }
    //var mensaje:string="";
    var arrPorID:any[]=[];
    this.fechane=this.dateStringControl.value;
    var f = moment(this.fechane);
    this.time=this.timeStringControl.value;

    this.time2=this.timeStringControl2.value;

    if(this.time==""&&this.time2!="")
    {
     alert("Elija una hora de Inicio");

    }
    else if(this.time!=""&&this.time2=="")
    {
      alert("Elija una hora Final");
    }
    else
    {
      this.fechane=this.dateStringControl.value;
      var f = moment(this.fechane);
      var fechaEv=moment(this.fechane);
      var fdEv= Date.parse(fechaEv.toString());

      var dfEv1=Date.parse(fechaEv.toString());
      //var datestr=moment(dfEv).format('LL');

      var datestr1=moment(dfEv1).subtract(1).format('LL');
      this.dateEv=datestr1;
      //var datestr=moment(fdEv).format('LL');
     // this.dateEv=datestr;

      this.time=this.timeStringControl.value;
      this.time2=this.timeStringControl2.value;
      var dates_as_int:any;
      var suma:any;
        if(this.time==""&&this.time2=="")
        {

         dates_as_int = Date.parse(f.toString());
         var datenew=dates_as_int.toString();

         datenew=datenew.substring(0,10);
         let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 1;
         suma =dates_as_int + semanaEnMilisegundos;
        // let fechaDentroDeUnaSemana = new Date(suma);
        this.showData(this.numval,dates_as_int,suma,dates_as_int,fdEv);

        }
        else
        {




          let fatenew=this.fechane+" "+this.time;
          let fatenew2=this.fechane+" "+this.time2;
          let fecha = new Date(fatenew);
          let fecha2 = new Date(fatenew2);

          var f = moment(fecha);
          var fn=f.format();
          var f2 = moment(fecha2);
          var fn2=f2.format();

         // var time_as_int = Date.parse(f.toString());
          //dates_as_int=time_as_int-18000000;
          //var time_as_int2 = Date.parse(f2.toString());
          //suma=time_as_int2-18000000;



          function filtrarPorID(obj:umData)
          {
            if ('Date' in obj && moment.utc(obj.Date).format() >= fn && moment.utc(obj.Date).format() <= fn2) {
              return true;
            } else {
              return false;
            }
          }

         arrPorID = this.dataUM.filter(filtrarPorID);

         console.log(arrPorID);

         this.chart.destroy();

         this.UpdateChart2(arrPorID,"", 0);

        }

    }

  }
  public pushOne(num:number,name:string): void {

    var params = JSON.parse(localStorage.getItem('paramsUM') || '{}');
    if(params.length==0||params.length==undefined)
    {
     this.getParams();
     params = JSON.parse(localStorage.getItem('paramsUM') || '{}');
    }
    params.forEach((element:any) => {
     if(num==0)
     {
       this.minmaxval="";
     }
     else
     {
       if(num==1)
       {
         this.minmaxval="Min: "+element.fuerza_min_inv+" Max: "+element.fuerza_max_inv;
         this.minval=element.fuerza_min_inv;
         this.maxval=element.fuerza_max_inv;

        // this.minmaxval="Min: "+element.FuerzaMin+" Max: "+element.FuerzaMax;
         //this.minval=element.FuerzaMin;
         //this.maxval=element.FuerzaMax;
       }
       else if(num==2)
       {
        //this.minmaxval="Min: "+element.PresionMin+" Max: "+element.PresionMax;
        //this.minval=element.PresionMin;
        //this.maxval=element.PresionMax;

         this.minmaxval="Min: "+element.presion_min_inv+" Max: "+element.presion_max_inv;
         this.minval=element.presion_min_inv;
         this.maxval=element.presion_max_inv;
       }
       else if(num==3)
       {
        // this.minmaxval="Min: "+element.CorrienteMin+" Max: "+element.CorrienteMax;
         //this.minval=element.CorrienteMin;
         //this.maxval=element.CorrienteMax;

         this.minmaxval="Min: "+element.corriente_min_inv+" Max: "+element.corriente_max_inv;
         this.minval=element.corriente_min_inv;
         this.maxval=element.corriente_max_inv;
       }
       else if(num==3)
       {
         //this.minmaxval="Min: "+element.CorrienteMin+" Max: "+element.CorrienteMax;
         //this.minval=element.CorrienteMin;
         //this.maxval=element.CorrienteMax;

         this.minmaxval="Min: "+element.corriente_min_inv+" Max: "+element.corriente_max_inv;
         this.minval=element.corriente_min_inv;
         this.maxval=element.corriente_max_inv;
       }
     }

    });

     this.UpdateChart2(this.dataUM,name, num);
  }
  UMSelect(value:any)
  {

    this.txtCorriente21=": ";
    this.txtCorriente22=": ";
    this.txtPresion2=": ";
    this.txtFuerza2=": ";
    this.txtFalla="Correcto";
    this.txtFuerza="OK";
    this.txtPresion="OK";
    this.txtCorriente="OK";
    this.txtCorriente2="OK";
    this.txtRueda="OK";
    this.txtGuarda="OK";

     this.fails=[];
     this.fails1=[];
     this.contfilltable=0;
   this.cowsStuck=[];



    this.chart.clear();
    this.chart.destroy();
    this.verSeleccion= value;
    this.fechane=this.dateStringControl.value;
    var f = moment(this.fechane);
   // var fformate = moment(this.fechane);

    var fechaEv=moment(this.fechane);
    var fdEv= Date.parse(fechaEv.toString());

    var dfEv1=Date.parse(fechaEv.toString());
    //var datestr=moment(dfEv).format('LL');

    var datestr1=moment(dfEv1).subtract(1).format('LL');
    this.dateEv=datestr1;


    this.time=this.timeStringControl.value;
    this.time2=this.timeStringControl2.value;
    var dates_as_int:any;
    var suma:any;

    if(this.time==""&&this.time2=="")
    {

     dates_as_int = Date.parse(f.toString());
     var datenew=dates_as_int.toString();

     datenew=datenew.substring(0,10);
     let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 1;
     suma =dates_as_int + semanaEnMilisegundos;
     let fechaDentroDeUnaSemana = new Date(suma);
    }
    else
    {
       let fatenew=this.fechane+" "+this.time;
       let fatenew2=this.fechane+" "+this.time2;
       let fecha = new Date(fatenew);
       let fecha2 = new Date(fatenew2);
       var f = moment(fecha);
       var f2 = moment(fecha2);

       var fechaEv=moment(fecha);
       var fdEv= Date.parse(fechaEv.toString());
       var dfEv1=Date.parse(fechaEv.toString());
       //var datestr=moment(dfEv).format('LL');

       var datestr1=moment(dfEv1).subtract(1).format('LL');
       this.dateEv=datestr1;
       //var datestr=moment(fdEv).format('LL');
       //this.dateEv=datestr;
       //var dates_as_int = Date.parse(this.fechane.toString());

       var time_as_int = Date.parse(f.toString());
       dates_as_int=time_as_int-18000000;
       var time_as_int2 = Date.parse(f2.toString());
       suma=time_as_int2-18000000;
    }

    var date = new Date();
    var dateform=moment(date).format("YYYY-MM-DD");

    var time1;
    var date1;;
    var cf:any;

    if(this.fechane.toString()==dateform)
    {
       time1 = moment.duration("03:00:00");
       date1 = moment(date)
       date1.subtract(time1);
       cf=date1.format();
    }
    else
    {
      time1 = moment.duration("03:00:00");
      date1 = moment(suma)
      date1.subtract(time1);
      cf=date1.format();
    }



   // this.listUM.forEach(element => {
     // if(this.verSeleccion=="")
      //{
        //this.verSeleccion= element.Value;
      //}
      //if(this.verSeleccion==element.Value)
      //{
       // this.numval=this.num;
        //this.ngOnInit(this.numval);
        this.CreateChart();
        this.showData(0,dates_as_int,suma,cf,fdEv);
      //}
   // });

   }

 UpdateChart2(arrPorID:umData[], name:string, num:number){


  var datenew;
// console.log(arrPorID);
    this.chart.destroy();
    var color=this.Color(num);
     if(num==0)
     {
      this.hideminmax=true;
      this.titval="General";
      this.CreateChart();
     }
     else if(num==3||num==4)
     {
      this.hideminmax=false;
      if(num==3)
      {
        this.titval="Corriente";
      }
      else{
        this.titval="Corriente2";
      }
      this.chart = new Chart('realtime', {
        type: 'line',
        data: {
          labels: [],
          datasets: [{
            label: name,
            fill: false,
            data: [],
            backgroundColor: color,
            borderColor: color,
            borderWidth : 3,
            pointRadius:0
            },
            {
              fill: false,
              label: 'Min',
              data: [],
              backgroundColor: '#C70039',
              borderColor: '#C70039',
              borderWidth : 1,
              pointRadius:0,
              borderDash: [5, 5]

            }


          ]
          },
          options: {
            responsive:true,
            maintainAspectRatio:true,
          tooltips: {
            enabled: true,
            intersect: true
          },
          hover:{mode: 'index', intersect: false,},
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#00528b',
              boxWidth: 8,
              fontSize:10,
            },


          },
          scales: {
            yAxes: [{
              ticks: {
                fontColor: '#168ede',
                fontSize:10
              },

            }]
            ,
            xAxes: [{type: 'time',
            time: {
              unit: 'hour'
          },
          distribution: 'linear',
           ticks: {
            autoSkipPadding: 5,
            fontColor: '#168ede',
            maxTicksLimit:10,
            padding:0,
          fontSize:9,}}]
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'xy'
              },
              zoom: {
                enabled: true,
                mode: 'xy'
              }
            }
          }
          }
      });
     }
     else
     {
      this.hideminmax=false;
       if(num==1)
       {
        this.titval="Fuerza";
       }
       else{
        this.titval="Presion";
       }
      this.chart = new Chart('realtime', {
        type: 'line',
        data: {
          labels: [],
          datasets: [{
            label: name,
            fill: false,
            data: [],
            backgroundColor: color,
            borderColor: color,
            borderWidth : 3,
            pointRadius:0
            },
            {
              fill: false,
              label: 'Max',
              data: [],
              backgroundColor: '#C70039',
              borderColor: '#C70039',
              borderWidth : 1,
              pointRadius:0,
              borderDash: [5, 5]

            },
            {
              fill: false,
              label: 'Min',
              data: [],
              backgroundColor: '#C70039',
              borderColor: '#C70039',
              borderWidth : 1,
              pointRadius:0,
              borderDash: [5, 5]

            }


          ]
          },
          options: {
            responsive:true,
            maintainAspectRatio:true,
          tooltips: {
            enabled: true,
            intersect: true
          },
          hover:{mode: 'index', intersect: false,},
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#00528b',
              boxWidth: 8,
              fontSize:10,
            },


          },
          scales: {
            yAxes: [{
              ticks: {
                fontColor: '#168ede',
                fontSize:10
              },

            }]
            ,
            xAxes: [{type: 'time',
            time: {
              unit: 'hour'
          },
          distribution: 'linear',
           ticks: {
            autoSkipPadding: 5,
            fontColor: '#168ede',
            maxTicksLimit:10,
            padding:0,
          fontSize:9,}}]
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'xy'
              },
              zoom: {
                enabled: true,
                mode: 'xy'
              }
            }
          }
          }
      });
     }

    arrPorID.forEach(element => {

      datenew=moment.utc(element.Date).format("YYYY-MM-DD HH:mm:ss");

      if(num==3||num==4)
      {
       // dateNew=moment(element.Date).utc().format("YYYY-MM-DD HH:mm:ss");
        if(num==3)
        {

          this.chart.data.labels.push(datenew);
          this.chart.data.datasets[0].data.push(element.Corriente1);
          this.chart.data.datasets[1].data.push(this.minval);
        }
        else if(num==4)
        {
          this.chart.data.labels.push(datenew);
          this.chart.data.datasets[0].data.push(element.Corriente2);
          this.chart.data.datasets[1].data.push(this.minval);
        }
      }
      else if(num==2||num==1)
      {
         //dateNew=moment(element.Date).utc().format("YYYY-MM-DD HH:mm:ss");
        if(num==1)
        {
          this.chart.data.labels.push(datenew);
          this.chart.data.datasets[0].data.push(element.Fuerza);
          this.chart.data.datasets[1].data.push(this.maxval);
          this.chart.data.datasets[2].data.push(this.minval);
        }
        else if(num==2)
        {
          this.chart.data.labels.push(datenew);
          this.chart.data.datasets[0].data.push(element.Presion);
          this.chart.data.datasets[1].data.push(this.maxval);
          this.chart.data.datasets[2].data.push(this.minval);
        }

      }
      else
      {

        this.chart.data.labels.push(datenew);
        this.chart.data.datasets[0].data.push(element.Fuerza);
        this.chart.data.datasets[1].data.push(element.Presion);
        this.chart.data.datasets[2].data.push(element.Corriente1);
        this.chart.data.datasets[3].data.push(element.Corriente2);
      }

    });

    this.chart.update();

   }


private Color(index:number):any{

  var color="";
  switch (index) {
    case 1:
      color="#1AAA08";
      break;
      case 2:
      color="#0A4AB6";
      break;
      case 3:
      color="#D35400";
      break;
      case 4:
      color="#F5B041";
      break;

  }
  return color;

}

updateScale()
{
 this.chart.options = {
  scales: {
    yAxes: [{
      type: 'linear',
      position: 'left' ,
      display: true,
      gridLines: { display: true},
       ticks: { min:this.min0,max:this.max0,
        fontColor: '#168ede',
        maxTicksLimit: 6,
        padding: 0,
        fontSize:10},
         scaleLabel: { display: true,
          labelString: this.des0,fontColor: '#00528b',lineHeight: 0,padding: 0,
          fontSize:10}
    }]
},
  responsive: true,
  maintainAspectRatio: false,
   layout: {
      padding: {
        left: 10,
        right: 10,
         top: 10,
          bottom: 10 } },
          tooltips:{ mode: 'point',
          intersect: true, backgroundColor: 'rgba(0, 0, 0, 0.6)'},
          hover: { mode: 'index', intersect: false, },
          legend: { position: 'bottom',
           labels: { fontSize: 9,
              boxWidth: 8, }, display: true,},
              elements: { line: { tension: 0.3 } },
};
}

  onLogout()
  {

    localStorage.removeItem('usuario');
    localStorage.removeItem('paramsUM');
    this.router.navigate(['login']);
    //localStorage.clear();
  }

}
