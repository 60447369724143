
<app-header [Tit]='nameItem'></app-header>
<img src="assets/loading.gif" [hidden]="load" class="loader" >

<label [hidden]="hidesuccess">{{txtsucc}}</label>

<div class="container-fluid" >
  <div class="container" >
    <h3 style="color:#21618C;">{{farmName}}</h3>
    <br>
     <div class="row">
      <div class="col-6">
        <div class="card card ">
          <div class="card-body text-center">
            <h5 class="card-title">HORARIO DE ORDEÑA</h5>
        <div class="row">
          <div class="col-12">
             1er Horario de Ordeño:
          </div>
          <div class="col-6">
            Desde:<input type="time" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='h1d'>
          </div>
          <div class="col-6">
            Hasta:<input type="time" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='h1h'>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
             2do Horario de Ordeño:
          </div>
          <div class="col-6">
            Desde:<input type="time" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='h2d'>
          </div>
          <div class="col-6">
            Hasta:<input type="time" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='h2h'>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
             3er Horario de Ordeño:
          </div>
          <div class="col-6">
            Desde:<input type="time" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='h3d'>
          </div>
          <div class="col-6">
            Hasta:<input type="time" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='h3h'>
          </div>
        </div>

      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-primary btn-sm" (click)="postSettings(1)">Guardar cambios</button>
          </div>
        </div>
      </div>

        </div>
      </div>
      <div class="col-6">
        <div class="card card ">
          <div class="card-body">
            <h5 class="card-title text-center">ALERTAS TANQUES DE LECHE</h5>
        <div class="row text-center">
          <div class="col-12">
             Alerta de Temperatura:
          </div>
          <div class="col-6">
            Min:<input type="number" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='mintmp0'>
          </div>
          <div class="col-6">
            Max:<input type="number" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='maxtmp0'>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-12">
            Alerta nivel de leche:
          </div>
          <div class="col-6">
            Val:<input type="number" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='min0Val'>
          </div>
          <div class="col-6">
            Val:<input type="number" class="form-control small" data-width="fit" aria-label="fit" style="font-size: xx-small;" [(ngModel)]='maxVal'>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-primary btn-sm" (click)="postSettings(2)">Guardar cambios</button>
          </div>
        </div>

      </div>


        </div>
      </div>
     </div>


  </div>
</div>
