
<div class="wrapper fadeInDown">
  <div id="formContent">
    <!-- Tabs Titles -->

    <!-- Icon -->
    <div class="fadeIn first">

      <div class="row">
        <div class="col-12">
          <img src="assets/icons/ControlMaestro_1000.png" id="icon" alt="User Icon" />
        </div>
      </div>
    </div>
    <!-- Login Form -->
    <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm.value) " [hidden]="formhide">
      <input type="text" id="userName" class="fadeIn second" placeholder="usuario" formControlName="userName">
     <!---- <input type="password" id="Password" class="fadeIn third" placeholder="password" formControlName="Password">-->
      <input type="password" id="password" class="fadeIn third" name="login" placeholder="contraseña" formControlName="password">
      <input type="submit" class="fadeIn fourth" value="Iniciar Sesion">
    </form>

    <form [hidden]="formhide2">
      <div class="row">
        <div class=col-12>
          <select  [(ngModel)]="opSlFarm" #t (change)="capturar(t.value)" name="first" class="btn btn-sm" style="background-color: white; color:#21618C; margin: 0.5rem;border-color: #95a5a6">
            <option *ngFor="let title of slFarm" [value]="title.Value">
              {{title.Text}}
            </option>
        </select>
        </div>
      </div>
      <div class="row">
        <div class=col-12>
          <input type="submit" value="Ir" (click)="onLogFarm()">
        </div>
      </div>
    </form>

    <!-- Remind Passowrd -->
    <div [hidden]="errorStatus" >
      <p class="text-danger">{{errorMsj}}</p>
     </div>

  </div>


</div>
<app-footer></app-footer>

