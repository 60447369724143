import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{Router, Params,ParamMap}from '@angular/router'

import{Chart}from 'chart.js'
import{ApiUMService}from '../servicios/apiUM.service';
import { Observable, Subject, timer } from 'rxjs'
import { FormControl } from '@angular/forms';
import { formatNumber, Time } from '@angular/common';
import { ParsedEvent } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { ValueAndText } from 'src/app/models/ValueAndText';
import { valueUM } from 'src/app/models/valueUm';
import * as moment from 'moment';
import { element } from 'protractor';

@Component({
  selector: 'app-milktemp',
  templateUrl: './milktemp.component.html',
  styleUrls: ['./milktemp.component.css']
})
export class MilktempComponent implements OnInit {

  /**
	* Interval to update the chart
	* @var {any} intervalUpdate
	*/
	private intervalUpdate: any = null;
	/**
	* The ChartJS Object
	* @var {any} chart
	*/

  public code0:string="";
  public code1:string="";
  public des0:string="";
  public txtMinI:any=0;
  public txtMaxI:any=0;
  public ScaleRight="";
  public ScaleLeft="";
  public alertHide=true;
  public load=false;
  public nameItem="";
  dateStringControl = new FormControl('');
  dateStringControl2 = new FormControl('');
  public chart: any = null;
  public var:any=null;
  public dataUM:any[]=[];
  public dataUMParam:any[]=[];
  public num: number=0;
  public hour:any=null;
  public txtnameFarm:any="";
  public fechastr:string="";
  public farmidg:any=0;
  public hourg:string="-1";
  public datesg:number=-1;
  public datefg:number=-1;
  public dateg:number=0;
  public username:string="";
  public valor1:any="";
  public titval:any="";
  date = new Date();
  nameindex:string="Establo ";
  public hidebtndate=true;
  public hidetabla=true;


  public min0:any=0;
  public max0:any=0;
  public rangoprom:any="";
  public promedio:any=0;
  public numval:number=0;
  public fechane:Date= new Date;
  public fechane2:Date= new Date;
  public time:any="";
  public time2:any="";
  public dateEv:any="";
  public listYield:any[]=[];
  public listTable:any[]=[];

  public paramsYieldTime:any=[]=[];

  constructor(private http: HttpClient,public api:ApiUMService, private router:Router) {
    this.nameItem="- Temperatura de leche"
    let list:any="";
    let listUserFarm:any=[];
    let datosUser = JSON.parse(localStorage.getItem('usuario') || '0');

    datosUser.forEach((element:any) => {
      listUserFarm=element.DataUser;
      list=element.DataFarm;

     });
    if(listUserFarm!=null&&listUserFarm!=0)
    {
     // list=datosUser.UserFarm;
      this.username=listUserFarm.UserName;
      this.valor1=this.username;
      this.txtnameFarm = localStorage.getItem('nameFarm');
      this.farmidg = localStorage.getItem('farmID');
      var fecha = new Date(); //Fecha actual
      var mes = fecha.getMonth()+1; //obteniendo mes
      var dia = fecha.getDate(); //obteniendo dia
      var ano = fecha.getFullYear(); //obteniendo año
      var diastr="";
      var messtr="";


      if(dia<10)
      {
        diastr='0'+dia;
      }
      else
      {
        diastr=dia.toString();
      }
      if(mes<10)
      {
        messtr='0'+mes
      }
      else
      {
        messtr=mes.toString();
      }
        this.fechastr=ano+"-"+messtr+"-"+diastr;
        this.dateStringControl = new FormControl(this.fechastr);
        this.dateStringControl2 = new FormControl(this.fechastr);
        this.fechastr=this.dateStringControl.value;


    }
    else
    {
      this.router.navigate(['login']);
    }

  }

  ngOnInit(): void {

    var date = new Date();
    var time1 = moment.duration("03:00:00");
    var date1 = moment(date)
    date1.subtract(time1);
    var cf=date1.format();

    this.CreateChart();



    var f = moment().subtract(15, 'd');
    var daten = moment().add(1, 'd');

    var dates_as_int = Date.parse(f.toString());
    var suma = Date.parse(daten.toString());

    this.showData(0,dates_as_int,suma, cf);



  }

  paramYield() {

    var paramsdatafarm="";
    paramsdatafarm= "getYieltime/"+this.farmidg;

    this.load=false;

		this.api.getFromAPIgen(paramsdatafarm).subscribe(response => {

			if(response.error === undefined) {
        this.dataUMParam=response;
        this.load=true;

			}
      //this.SpinnerService.hide();

    });

  }

  CreateChart(){
		this.chart = new Chart('realtime', {
			type: 'line',
			data: {
				labels: [],
				datasets: [{
          label: 'Nivel de Leche',
          fill: false,
          data: [],
          backgroundColor: '#1AAA08',
          borderColor: '#1AAA08',
          borderWidth : 3,
          pointRadius:0,
          },
          {barPercentage:1.0,
            categoryPercentage:1.0,
            type: 'bar',
            label: 'Ordeña',
            backgroundColor: '#335AFF',
            borderColor: '335AFF',
            data: [],


          }
				]
			  },
			  options: {
          responsive:true,
          maintainAspectRatio:true,
          layout: {padding:{left:10,right: 10,top: 10}},
				tooltips: {
					enabled: true,
          intersect: true
				},
        hover:{mode: 'index', intersect: false,},
				legend: {
					display: true,
					position: 'bottom',
					labels: {
						fontColor: '#00528b',
            boxWidth: 8,
            fontSize:10,
					},


				},
				scales: {
				  yAxes: [{
					  ticks: {
						  fontColor: '#168ede',
              fontSize:10,

					  },
            scaleLabel: { display: true,
              labelString:  'Litros',
                    fontColor: '#00528b',
                    padding: 1
                    }

				  }]
          ,
				  xAxes: [{type: 'time',
          time: {
            unit: 'hour'
        },
        distribution: 'linear',
         ticks: {
          autoSkipPadding: 5,
          fontColor: '#168ede',
          maxTicksLimit:10,
          padding:0,
        fontSize:9,},


      }]
				},
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy'
            },
            zoom: {
              enabled: true,
              mode: 'xy'
            }
          }
        }
			  }
		});
  }

  private showData(num1:number, dateStart:number, dateFin:number,date1:any): void {

    var params="";
    this.numval=num1;
    var num:number;
    var contyd=0;

    var str:any;
    var str2:any;
    var stry2:any;
    var stry22:any;
    var stry3:any;
    var stry33:any;
    var stry4:any;
    var stry44:any;
    var stry5:any;
    var stry55:any;
    var stry6:any;
    var stry66:any;

    var paramsdatafarm="";
    paramsdatafarm= "getYieltime/"+this.farmidg;

    this.load=false;

    this.api.getFromAPIgen(paramsdatafarm).subscribe(response => {

			if(response.error === undefined) {
        this.dataUMParam=response;

        this.dataUMParam.forEach((element:any) => {


          if(element.time1!=null&&element.finaltime1!=null)
          {
              str=moment.utc(element.time1).format('HH:mm:ss');
              str2=moment.utc(element.finaltime1).format('HH:mm:ss');
          }
         if(element.time2!=null&&element.finaltime2!=null)
          {
            stry2=moment.utc(element.time2).format('HH:mm:ss');
            stry22=moment.utc(element.finaltime2).format('HH:mm:ss');
          }
         if(element.time3!=null&&element.finaltime3!=null)
          {
            stry3=moment.utc(element.time3).format('HH:mm:ss');
            stry33=moment.utc(element.finaltime3).format('HH:mm:ss');
          }
          if(element.time4!=null&&element.finaltime4!=null)
          {
            stry4=moment.utc(element.time4).format('HH:mm:ss');
            stry44=moment.utc(element.finaltime4).format('HH:mm:ss');
          }
          if(element.time5!=null&&element.finaltime5!=null)
          {
            stry5=moment.utc(element.time5).format('HH:mm:ss');
            stry55=moment.utc(element.finaltime5).format('HH:mm:ss');
          }
          if(element.time6!=null&&element.finaltime6!=null)
          {
            stry6=moment.utc(element.time6).format('HH:mm:ss');
            stry66=moment.utc(element.finaltime6).format('HH:mm:ss');
          }

        });

        params= "getMilkLevel/"+this.farmidg+"/"+dateStart+"/"+dateFin;



        this.api.getFromAPIgen(params).subscribe(response => {
          this.dataUM=response;


          if(response.error === undefined) {


              var arrPorID:any[]=[];
               num=this.dataUM.length;
               var contstrdate=0;
               var promedio=0;
               var suma=0;
               var cont=0;

               var sumOrd=0;
               var valfirst1=-1;
               var valfin1=0;

               var valfirst2=-1;
               var valfin2=0;

               var valfirst3=-1;
               var valfin3=0;
               var dateNew="";

               var dateNewTable="";
               var dateNewTable2="";
               var dateNewTable3="";

               var dif=0;

               var conto1=0;
               this.rangoprom="";


                  if(num1==0)
                  {
                    function filtrarPorID(obj:any)
                    {
                      if ('Date' in obj && (obj.Date) >= date1) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                     arrPorID = this.dataUM.filter(filtrarPorID);
                   // console.log( arrPorID);

                    arrPorID.forEach(element => {
                      dif=0;
                      // this.hour=element.Date;
                     dateNew=moment.utc(element.Date).format("YYYY-MM-DD HH:mm:ss");
                       this.chart.data.labels.push(dateNew);
                       if(contstrdate==0)
                       {
                        contstrdate=1;
                        this.rangoprom=dateNew;
                       }


                       if(moment.utc(element.Date).format('HH:mm')>=str && moment.utc(element.Date).format('HH:mm')<=str2)
                       {

                        if(element.Milklevel!=null)
                      {
                       if(moment.utc(element.Date).format('HH:mm')==str||valfirst1==-1)
                       {
                         if(valfirst1!=-1)
                         {

                          dif=valfin1-valfirst1;
                        //  console.log("fin: "+valfin1+" inicio: "+valfirst1+" dif: "+dif+" ordeño 1");
                         this.listTable.push({Date:dateNewTable, Ordena: str+"-"+str2, Inicio:valfirst1.toString(), Final:valfin1.toString(),Diferencia:dif.toString()});
                          suma=suma+dif;
                          dif=0;
                          valfin1=-1;
                          conto1=conto1+1;

                         }
                         dateNewTable=dateNew;
                         valfirst1=element.Milklevel;

                       }
                       if(element.Milklevel>valfin1)
                       {
                         valfin1=element.Milklevel;
                       }
                      this.chart.data.datasets[1].data.push(element.Milklevel);

                     }
                     else{
                      this.chart.data.datasets[1].data.push(undefined);
                     }

                       }
                       else if(moment.utc(element.Date).format('HH:mm')>=stry2 && moment.utc(element.Date).format('HH:mm')<=stry22)
                       {
                        if(element.Milklevel!=null)
                        {
                         if(moment.utc(element.Date).format('HH:mm')==stry2||valfirst2==-1)
                          {
                            if(valfirst2!=-1)
                            {

                             dif=valfin2-valfirst2;
                            // console.log("fin: "+valfin2+" inicio: "+valfirst2+" dif: "+dif+" ordeño 2");
                             this.listTable.push({Date:dateNewTable2, Ordena: stry2+"-"+stry22, Inicio:valfirst2.toString(), Final:valfin2.toString(),Diferencia:dif.toString()});

                             suma=suma+dif;
                             valfin2=-1;
                             dif=0;
                             conto1=conto1+1;

                            }
                            dateNewTable2=dateNew;
                            valfirst2=element.Milklevel;

                          }
                          if(element.Milklevel>valfin2)
                          {
                            valfin2=element.Milklevel;
                          }
                         this.chart.data.datasets[1].data.push(element.Milklevel);

                        }
                        else{
                         this.chart.data.datasets[1].data.push(undefined);
                        }
                       }
                       else if(moment.utc(element.Date).format('HH:mm')>=stry3 || moment.utc(element.Date).format('HH:mm')<=stry33)
                       {
                        if(element.Milklevel!=null)
                        {
                         if(moment.utc(element.Date).format('HH:mm')==stry3||valfirst3==-1)
                          {
                            if(valfirst3!=-1)
                            {

                             dif=valfin3-valfirst3;
                            // console.log("fin: "+valfin3+" inicio: "+valfirst3+" dif: "+dif+" ordeño 3");
                             this.listTable.push({Date:dateNewTable3, Ordena: stry3+"-"+stry33, Inicio:valfirst3.toString(), Final:valfin3.toString(),Diferencia:dif.toString()});

                             suma=suma+dif;
                             valfin3=-1;
                             dif=0;
                             conto1=conto1+1;

                            }
                            dateNewTable3=dateNew;
                            valfirst3=element.Milklevel;

                          }
                          if(element.Milklevel>valfin3)
                          {
                            valfin3=element.Milklevel;
                          }
                         this.chart.data.datasets[1].data.push(element.Milklevel);

                        }
                        else{
                         this.chart.data.datasets[1].data.push(undefined);
                        }
                       }
                       else{
                         this.chart.data.datasets[1].data.push(undefined);
                       }

                       if(element.Milklevel!=null)
                       {
                         this.chart.data.datasets[0].data.push(element.Milklevel);
                       }
                       else{
                        this.chart.data.datasets[0].data.push(undefined);
                       }

                       this.chart.update();


                   });

                   if(valfirst1!=-1)
                   {

                    dif=valfin1-valfirst1;
                   // console.log("fin: "+valfin1+" inicio: "+valfirst1+" dif: "+dif+" ordeño 1");
                    this.listTable.push({Date:dateNewTable, Ordena: str+"-"+str2, Inicio:valfirst2.toString(), Final:valfin2.toString(),Diferencia:dif.toString()});

                    suma=suma+dif;
                    dif=0;
                    valfin1=-1;
                    conto1=conto1+1;

                   }
                   if(valfirst2!=-1)
                   {

                    dif=valfin2-valfirst2;
                   // console.log("fin: "+valfin2+" inicio: "+valfirst2+" dif: "+dif+" ordeño 2");
                    this.listTable.push({Date:dateNewTable2, Ordena: stry2+"-"+stry22, Inicio:valfirst2.toString(), Final:valfin2.toString(),Diferencia:dif.toString()});

                    suma=suma+dif;
                    valfin2=-1;
                    dif=0;
                    conto1=conto1+1;

                   }
                   if(valfirst3!=-1)
                   {

                    dif=valfin3-valfirst3;
                   // console.log("fin: "+valfin3+" inicio: "+valfirst3+" dif: "+dif+" ordeño 3");
                    this.listTable.push({Date:dateNewTable3, Ordena: stry3+"-"+stry33, Inicio:valfirst3.toString(), Final:valfin3.toString(),Diferencia:dif.toString()});
                    suma=suma+dif;
                    valfin3=-1;
                    dif=0;
                    conto1=conto1+1;

                   }


           // console.log("suma: "+suma);
            //console.log("cont: "+conto1);


            if(suma!=0)
            {
              promedio=Math.round(suma/conto1);
            }



            this.promedio=promedio;

                  }
                  else
                  {
                    this.UpdateChart2(this.dataUM,this.titval,num1);
                  }

                }
                //this.SpinnerService.hide();
                this.load=true;
              });



			}

      //this.load=true;
    });





	}


  grafShare(val:any) {
    this.listTable=[];
    //this.load=false;
     var arrPorID:any[]=[];


       var dates_as_int:any;
       var suma:any;
       if(val==0)
       {
           this.hidebtndate=false;
       }
       else if(val==5)
         {
          this.chart.destroy();
          this.hidebtndate=false;
          this.fechane=this.dateStringControl.value;
          this.fechane2=this.dateStringControl2.value;
          var f = moment(this.fechane);
          var f2 = moment(this.fechane2);

          if(this.fechane.toString()==""&&this.fechane2.toString()!="")
          {
           alert("Elija una hora de Inicio");

          }
          else if(this.fechane.toString()!=""&&this.fechane2.toString()=="")
          {
            alert("Elija una hora Final");
          }
          else
          {
            dates_as_int = Date.parse(f.toString());
            suma = Date.parse(f2.toString());
           // let fechaDentroDeUnaSemana = new Date(suma);
           this.showData(this.numval,dates_as_int,suma,f.format());
          }

         }
         else
         {
          this.hidebtndate=true;
          var f=moment();
          var f2=moment();
          var fn:any;
          var fn2:any;

          if(val==1)
          {
            f = moment();
            f2 = moment().add(1,'d');
            fn=f.format('YYYY-MM-DD');
            fn2=f2.format('YYYY-MM-DD');
          }
          else if(val==2)
          {
            f = moment().subtract(5, 'd');
            fn=f.format();
            fn2=f2.format();
          }
          else if(val==3)
          {
            f = moment().subtract(10, 'd');
            fn=f.format();
            fn2=f2.format();
          }
          else if(val==4)
          {
            f = moment().subtract(15, 'd');
            fn=f.format();
            fn2=f2.format();
          }





           function filtrarPorID(obj:any)
           {
             var c=moment.utc(obj.Date);
            var d=c.format();


             if ('Date' in obj && d>=fn &&d <= fn2) {
               return true;
             } else {
               return false;
             }
           }

          arrPorID = this.dataUM.filter(filtrarPorID);

         // console.log(arrPorID);

          this.chart.destroy();

          this.UpdateChart2(arrPorID,"", val);

         }

   }


   UpdateChart2(arrPorID:any[], name:string, num:number){

    var datenew="";
  // console.log(arrPorID);
      this.chart.destroy();
     // var color=this.Color(num);
       if(num==1)
       {
        this.CreateChart();
       }
       else
       {
        this.chart = new Chart('realtime', {
          type: 'line',
          data: {
            labels: [],
            datasets: [{
              label: 'Temperatura de Leche',
              fill: false,
              data: [],
              backgroundColor: '#1AAA08',
              borderColor: '#1AAA08',
              borderWidth : 3,
              pointRadius:0,
              },
              {
                barPercentage:1.0,
                categoryPercentage:1.0,
                type: 'bar',
                label: 'Ordeña',
                backgroundColor: '#335AFF',
                borderColor: '#335AFF',
                data: [],
              }
            ]
            },
            options: {
              responsive:true,
              maintainAspectRatio:true,
              layout: {padding:{left:10,right: 10,top: 10}},
            tooltips: {
              enabled: true,
              intersect: true
            },
            hover:{mode: 'index', intersect: false,},
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                fontColor: '#00528b',
                boxWidth: 8,
                fontSize:10,
              },


            },
            scales: {
              yAxes: [{
                ticks: {
                  fontColor: '#168ede',
                  fontSize:10
                },
                scaleLabel: { display: true,
                  labelString:  'Grados',
                        fontColor: '#00528b',
                        padding: 1}

              }]
              ,
              xAxes: [{type: 'time',
              time: {
                unit: 'day'
            },
            distribution: 'linear',
             ticks: {
              autoSkipPadding: 5,
              fontColor: '#168ede',
              maxTicksLimit:10,
              padding:0,
            fontSize:9,}}]
            },
            plugins: {
              zoom: {
                pan: {
                  enabled: true,
                  mode: 'xy'
                },
                zoom: {
                  enabled: true,
                  mode: 'xy'
                }
              }
            }
            }
        });
       }

       var promedio=0;
       var suma=0;
       var cont=0;

       var sumOrd=0;
       var valfirst1=-1;
       var valfin1=0;

       var valfirst2=-1;
       var valfin2=0;

       var valfirst3=-1;
       var valfin3=0;


       var dif=0;

       var conto1=0;

       var str:any;
    var str2:any;
    var stry2:any;
    var stry22:any;
    var stry3:any;
    var stry33:any;
    var stry4:any;
    var stry44:any;
    var stry5:any;
    var stry55:any;
    var stry6:any;
    var stry66:any;

    var dateNewTable="";
    var dateNewTable2="";
    var dateNewTable3="";

    var contstrdate=0;
    this.load=false;
        this.dataUMParam.forEach((element:any) => {


          if(element.time1!=null&&element.finaltime1!=null)
          {
              str=moment.utc(element.time1).format('HH:mm');
              str2=moment.utc(element.finaltime1).format('HH:mm');
          }
         if(element.time2!=null&&element.finaltime2!=null)
          {
            stry2=moment.utc(element.time2).format('HH:mm');
            stry22=moment.utc(element.finaltime2).format('HH:mm');
          }
         if(element.time3!=null&&element.finaltime3!=null)
          {
            stry3=moment.utc(element.time3).format('HH:mm');
            stry33=moment.utc(element.finaltime3).format('HH:mm');
          }
          if(element.time4!=null&&element.finaltime4!=null)
          {
            stry4=moment.utc(element.time4).format('HH:mm');
            stry44=moment.utc(element.finaltime4).format('HH:mm');
          }
          if(element.time5!=null&&element.finaltime5!=null)
          {
            stry5=moment.utc(element.time5).format('HH:mm');
            stry55=moment.utc(element.finaltime5).format('HH:mm');
          }
          if(element.time6!=null&&element.finaltime6!=null)
          {
            stry6=moment.utc(element.time6).format('HH:mm');
            stry66=moment.utc(element.finaltime6).format('HH:mm');
          }

        });

      // const dateyield=moment(str, 'YYYY-MM-DD HH:mm:ss');
       //const dateyieldfin=moment(str2, 'YYYY-MM-DD HH:mm:ss');

       //const dateyield2=moment(stry2, 'YYYY-MM-DD HH:mm:ss');
       //const dateyieldfin2=moment(stry22, 'YYYY-MM-DD HH:mm:ss');

       //const dateyield3=moment(stry3, 'YYYY-MM-DD HH:mm:ss');
      // const dateyieldfin3=moment(stry33, 'YYYY-MM-DD HH:mm:ss');

      this.rangoprom="";

      arrPorID.forEach(element => {
        dif=0;

        datenew=moment.utc(element.Date).format("YYYY-MM-DD HH:mm:ss");
        if(contstrdate==0)
        {
          contstrdate=1;
          this.rangoprom=datenew;
        }
        this.chart.data.labels.push(datenew);
          if(moment.utc(element.Date).format('HH:mm')>=str && moment.utc(element.Date).format('HH:mm')<=str2)
          {
            if(element.Milklevel!=null)
               {
                 if(moment.utc(element.Date).format('HH:mm')==str||valfirst1==-1)
                 {
                   if(valfirst1!=-1)
                   {

                    dif=valfin1-valfirst1;
                   // console.log("fin: "+valfin1+" inicio: "+valfirst1+" dif: "+dif+" ordeño 1");
                    this.listTable.push({Date:dateNewTable, Ordena: str+"-"+str2, Inicio:valfirst1.toString(), Final:valfin1.toString(),Diferencia:dif.toString()});

                    suma=suma+dif;
                    dif=0;
                    valfin1=-1;
                    conto1=conto1+1;

                   }
                   valfirst1=element.Milklevel;
                   dateNewTable=datenew;

                 }
                 if(element.Milklevel>valfin1)
                 {
                   valfin1=element.Milklevel;
                 }
                this.chart.data.datasets[1].data.push(element.Milklevel);

               }
               else{
                this.chart.data.datasets[1].data.push(undefined);
               }
          }
          else if(moment.utc(element.Date).format('HH:mm')>=stry2 && moment.utc(element.Date).format('HH:mm')<=stry22)
          {
              if(element.Milklevel!=null)
               {
                if(moment.utc(element.Date).format('HH:mm')==stry2||valfirst2==-1)
                 {
                   if(valfirst2!=-1)
                   {

                    dif=valfin2-valfirst2;
                    //console.log("fin: "+valfin2+" inicio: "+valfirst2+" dif: "+dif+" ordeño 2");
                    this.listTable.push({Date:dateNewTable2, Ordena: stry2+"-"+stry22, Inicio:valfirst2.toString(), Final:valfin2.toString(),Diferencia:dif.toString()});
                    suma=suma+dif;
                    valfin2=-1;
                    dif=0;
                    conto1=conto1+1;

                   }
                   valfirst2=element.Milklevel;
                   dateNewTable2=datenew;

                 }
                 if(element.Milklevel>valfin2)
                 {
                   valfin2=element.Milklevel;
                 }
                this.chart.data.datasets[1].data.push(element.Milklevel);

               }
               else{
                this.chart.data.datasets[1].data.push(undefined);
               }
          }
          else if(moment.utc(element.Date).format('HH:mm')>=stry3 || moment.utc(element.Date).format('HH:mm')<=stry33)
          {
            if(element.Milklevel!=null)
               {
                if(moment.utc(element.Date).format('HH:mm')==stry3||valfirst3==-1)
                 {
                   if(valfirst3!=-1)
                   {

                    dif=valfin3-valfirst3;
                   // console.log("fin: "+valfin3+" inicio: "+valfirst3+" dif: "+dif+" ordeño 3");
                    this.listTable.push({Date:dateNewTable3, Ordena: stry3+"-"+stry33, Inicio:valfirst3.toString(), Final:valfin3.toString(),Diferencia:dif.toString()});

                    suma=suma+dif;
                    valfin3=-1;
                    dif=0;
                    conto1=conto1+1;

                   }
                   valfirst3=element.Milklevel;
                   dateNewTable3=datenew;

                 }
                 if(element.Milklevel>valfin3)
                 {
                   valfin3=element.Milklevel;
                 }
                this.chart.data.datasets[1].data.push(element.Milklevel);

               }
               else{
                this.chart.data.datasets[1].data.push(undefined);
               }
          }
          else{
            this.chart.data.datasets[1].data.push(undefined);
          }



          if(element.Milklevel!=null)
          {
            this.chart.data.datasets[0].data.push(element.Milklevel);
          }
          else{
           this.chart.data.datasets[0].data.push(undefined);
          }


      });

                   if(valfirst1!=-1)
                   {

                    dif=valfin1-valfirst1;
                   // console.log("fin: "+valfin1+" inicio: "+valfirst1+" dif: "+dif+" ordeño 1");
                    this.listTable.push({Date:dateNewTable, Ordena: str+"-"+str2, Inicio:valfirst1.toString(), Final:valfin1.toString(),Diferencia:dif.toString()});

                    suma=suma+dif;
                    dif=0;
                    valfin1=-1;
                    conto1=conto1+1;

                   }
                   if(valfirst2!=-1)
                   {

                    dif=valfin2-valfirst2;
                   //console.log("fin: "+valfin2+" inicio: "+valfirst2+" dif: "+dif+" ordeño 2");
                    this.listTable.push({Date:dateNewTable2, Ordena: stry2+"-"+stry22, Inicio:valfirst2.toString(), Final:valfin2.toString(),Diferencia:dif.toString()});

                    suma=suma+dif;
                    valfin2=-1;
                    dif=0;
                    conto1=conto1+1;

                   }
                   if(valfirst3!=-1)
                   {

                    dif=valfin3-valfirst3;
                   //console.log("fin: "+valfin3+" inicio: "+valfirst3+" dif: "+dif+" ordeño 3");
                    this.listTable.push({Date:dateNewTable3, Ordena: stry3+"-"+stry33, Inicio:valfirst3.toString(), Final:valfin3.toString(),Diferencia:dif.toString()});
                    suma=suma+dif;
                    valfin3=-1;
                    dif=0;
                    conto1=conto1+1;

                   }

         this.load=true;

      this.chart.update();

    // console.log("suma: "+suma);
      //console.log("cont: "+conto1);

           if(suma!=0)
            {
              promedio=Math.round(suma/conto1);
            }
      //promedio=(suma/conto1);
     // console.log("prom: "+promedio);

      this.promedio=promedio;


     }


private Color(index:number):any{

  var color="";
  switch (index) {
    case 1:
      color="#1AAA08";
      break;
      case 2:
      color="#0A4AB6";
      break;
      case 3:
      color="#D35400";
      break;
      case 4:
      color="#F5B041";
      break;

  }
  return color;

}




}
