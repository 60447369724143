import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{Router, Params,ParamMap}from '@angular/router'

import{Chart}from 'chart.js'
import { umData } from '../../models/modelsUM/dataUM';
import{ApiUMService}from '../../servicios/apiUM.service';
import { Observable, Subject, timer } from 'rxjs'
import { FormControl } from '@angular/forms';
import { formatNumber, Time } from '@angular/common';
import { ParsedEvent } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { ValueAndText } from 'src/app/models/ValueAndText';
import { valueUM } from 'src/app/models/valueUm';
import * as moment from 'moment';

@Component({
  selector: 'app-lavadomob',
  templateUrl: './lavadomob.component.html',
  styleUrls: ['./lavadomob.component.css']
})
export class LavadomobComponent {
 /**
	* Interval to update the chart
	* @var {any} intervalUpdate
	*/
	private intervalUpdate: any = null;
	/**
	* The ChartJS Object
	* @var {any} chart
	*/


  public hideMsj=true;
  public code0:string="";
  public code1:string="";
  public des0:string="";
  public txtMinI:any=0;
  public txtMaxI:any=0;
  public ScaleRight="";
  public ScaleLeft="";
  public alertHide=true;
  public load=false;
  public nameItem="";
  dateStringControl = new FormControl('');
  dateStringControl2 = new FormControl('');
  public chart: any = null;
  public var:any=null;
  public dataUM:any[]=[];
  public num: number=0;
  public hour:any=null;
  public txtnameFarm:any="";
  public fechastr:string="";
  public farmidg:any=0;
  public hourg:string="-1";
  public datesg:number=-1;
  public datefg:number=-1;
  public dateg:number=0;
  public username:string="";
  public valor1:any="";
  public titval:any="";
  date = new Date();
  nameindex:string="Establo ";
  public hidebtndate=true;
  public hidetabla=true;
  public listLav:any[]=[];


  public min0:any=0;
  public max0:any=0;
  public rangoprom:any="";
  public promedio:any="";
  public numval:number=0;
  public fechane:Date= new Date;
  public fechane2:Date= new Date;
  public time:any="";
  public time2:any="";
  public dateEv:any="";
  public listYield:any[]=[];


   constructor(private http: HttpClient,public api:ApiUMService, private router:Router) {
    this.nameItem="- Lavado"
    let list:any="";
    let datosUser = JSON.parse(localStorage.getItem('usuario') || '0');

    if(datosUser!=null&&datosUser!=0)
    {
      list=datosUser.UserFarm;
      this.username=datosUser.UserName;
      this.valor1=this.username;
      this.txtnameFarm = localStorage.getItem('nameFarm');
      this.farmidg = localStorage.getItem('farmID');
      var fecha = new Date(); //Fecha actual
      var mes = fecha.getMonth()+1; //obteniendo mes
      var dia = fecha.getDate(); //obteniendo dia
      var ano = fecha.getFullYear(); //obteniendo año
      var diastr="";
      var messtr="";


      if(dia<10)
      {
        diastr='0'+dia;
      }
      else
      {
        diastr=dia.toString();
      }
      if(mes<10)
      {
        messtr='0'+mes
      }
      else
      {
        messtr=mes.toString();
      }
        this.fechastr=ano+"-"+messtr+"-"+diastr;
        this.dateStringControl = new FormControl(this.fechastr);
        this.dateStringControl2 = new FormControl(this.fechastr);
        this.fechastr=this.dateStringControl.value;


    }
    else
    {
      this.router.navigate(['login']);
    }
  }

  private ngOnInit(num1:number): void {

    var date = new Date();
    var time1 = moment.duration("03:00:00");
    var date1 = moment(date)
    date1.subtract(time1);
    var cf=date1.format();


   if(num1==undefined)
   {

   // var f = moment().subtract(1, 'd');
   var f = moment();
    var daten = moment().add(1, 'd');

    var dates_as_int = Date.parse(f.toString());
    var suma = Date.parse(daten.toString());

    this.showData(0,dates_as_int,suma);

   }

	}

  private showData(num1:number, dateStart:number, dateFin:number): void {

    var params="";
    this.numval=num1;
    var num:number;


   params= "getLavado/"+this.farmidg+"/"+dateStart+"/"+dateFin;

    this.load=false;

		this.api.getFromAPIgen(params).subscribe(response => {

			if(response.error === undefined) {
        this.dataUM=response;

        if(this.dataUM.length>0)
        {
          this.hideMsj=true;
          this.dataUM.forEach(element => {


            var duracion:any;
            var duracionmin:any;
            var duracionsec:any;
            if(element.inicio!=null&&element.fin!=null)
            {
              var date=moment.utc(element.inicio);
              var datefin=moment.utc(element.fin);

              console.log(datefin.diff(date, 'hours'), ' minutos de diferencia');
                 duracion=datefin.diff(date, 'hours');
                 console.log(datefin.diff(date, 'minutes'), ' minutos de diferencia');
                 duracionmin=datefin.diff(date, 'minutes');
                 console.log(datefin.diff(date, 'seconds'), ' minutos de diferencia');
                 duracionsec=datefin.diff(date, 'seconds');

                  // Calcula los minutos de cada hora
            // var minutos_inicio = element.inicio.split(':')
             //.reduce((p:any, c:any) => parseInt(p) * 60 + parseInt(c));
              //var minutos_final =element.fin.split(':')
              //.reduce((p:any, c:any) => parseInt(p) * 60 + parseInt(c));

                // Si la hora final es anterior a la hora inicial sale
              //if (minutos_final < minutos_inicio) return;

              // Diferencia de minutos
              //var diferencia = minutos_final - minutos_inicio;

             // Cálculo de horas y minutos de la diferencia
            // var horas = Math.floor(duracion / 60);
             //var minutos = duracion % 60;
             //var seconds = minutos % 60;
            this.listLav.push({Date:moment.utc(element.date).format("YYYY-MM-DD"), Inicio:date.format('HH:mm:ss'), Fin:datefin.format('HH:mm:ss'), Lavado:element.lavado, Duracion:duracion + ':'
            + (duracionmin < 10 ? '0' : '') + duracionmin});
            }



          });
        }
        else{
         this.hideMsj=false;
        }




			}
      //this.SpinnerService.hide();
      this.load=true;
    });

	}
  Share()
  {

    this.load=false;
    this.listLav=[];
    this.fechane=this.dateStringControl.value;
    var f = moment(this.fechane);

    this.fechane2=this.dateStringControl2.value;
    var f2 = moment(this.fechane2);
    f2 = moment(f2).add(1, 'd');



    var dates_as_int = Date.parse(f.toString());

    var dates_as_intf = Date.parse(f2.toString());

    this.showData(0,dates_as_int,dates_as_intf);

  }
}
