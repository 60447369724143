<footer class="text-secondary d-print-none">
  <form>
    <div class="container-fluid">
      <div class="float-right"><img src="assets/Keepmilking-01.png" style="height:50px"/></div>
      <p style="font-size:xx-small" class="text-primary">&copy; {{date}} Developed by Madero Equipos de Ordeño</p>
    </div>

  </form>

</footer>
