import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './vistas/login/login.component';
import { DashboardComponent } from './vistas/dashboard/dashboard.component';
import {UnidadesmotricesComponent}from './vistas/unidadesmotrices/unidadesmotrices.component';
import {ListasComponent} from './plantillas/listas/listas.component';
import { FlujoLecheComponent } from './vistas/flujoLeche/flujoLeche.component';
import { LavadoComponent } from './vistas/lavado/lavado.component';
import { UnidadesmotricesmobileComponent } from './vistasmobile/unidadesmotricesmobile/unidadesmotricesmobile.component';
import { IniciounideadesmotricesComponent } from './vistasmobile/iniciounideadesmotrices/iniciounideadesmotrices.component';
import { LavadomobComponent } from './vistasmobile/lavadomob/lavadomob.component';
import { NivelvacioComponent } from './vistas/nivelvacio/nivelvacio.component';
import { SettingsComponent } from './settings/settings.component';
import { MilktempComponent } from './milktemp/milktemp.component';


const routes: Routes = [
  {path:'', redirectTo:'login',pathMatch:'full'},
  {path:'login', component:LoginComponent},
  {path:'dashboard', component:DashboardComponent},
  {path:'unidadesmotrices',component:UnidadesmotricesComponent},
  {path:'tanquesleche',component:FlujoLecheComponent},
  {path:'lavado',component:LavadoComponent},
  {path:'listas/:Tit',component:ListasComponent},
  {path:'unidadesmotricesmob',component:UnidadesmotricesmobileComponent},
  {path:'homeunidadesmotrices',component:IniciounideadesmotricesComponent},
  {path:'lavadomob',component:LavadomobComponent},
  {path:'vacio',component:NivelvacioComponent},
  {path:'settings',component:SettingsComponent},
  {path:'milktemp',component:MilktempComponent}





];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents=[LoginComponent,DashboardComponent,UnidadesmotricesComponent,ListasComponent,
  FlujoLecheComponent,LavadoComponent,
  UnidadesmotricesmobileComponent,
  IniciounideadesmotricesComponent,
  LavadomobComponent, NivelvacioComponent,
  SettingsComponent,
  MilktempComponent,]
