import { Component, Input, OnInit } from '@angular/core';
import {Router}from '@angular/router';
import { ValueAndText } from 'src/app/models/ValueAndText';
import { LoginList } from 'src/app/models/modelsLogin/LoginList';
import{ApiUMService}from '../../servicios/apiUM.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { paramsUM } from 'src/app/models/modelsUM/paramsUM';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public valor1:any="";
  public username:string="";
  public farmidg:any="";
  public farmName:any="";
  public farmNamestr:string="";
  public listFarm:ValueAndText[]=[];
  public formhide=false;
  public formhide2=true;
  public opSlFarm: string  = '0';

  verSeleccion: string = '';
  activate: Boolean = false;

  @Input()Tit:string="";
  constructor(private http: HttpClient,public api:ApiUMService, private router:Router) {


    let listUserFarm:any=[];
  let list:any="";
  let datosUser = JSON.parse(localStorage.getItem('usuario') || '0');

  if(datosUser==0)
  {
    this.router.navigate(['login']);
  }
  else
  {
    datosUser.forEach((element:any) => {
      listUserFarm=element.DataUser;
      list=element.DataFarm;

     });

    if(listUserFarm!=null&&listUserFarm!=0)
    {

      this.username=listUserFarm.UserName;
      this.valor1=this.username;
      this.farmName = localStorage.getItem('nameFarm');
      this.farmidg = localStorage.getItem('farmID');
      let listnew:any[]= [];
      listnew=list;
      let listt:any[]=[];
      if(listnew.length>0)
      {
         this.formhide2=false;
         this.formhide=true;
         listnew.forEach(element => {
          listt.push({Value:(element.FarmID).toString(),Text:element.NameFarm});
        });

        if(listt.length>0)
        {

            this.listFarm = (listt as any);

            this.formhide=true;
            this.formhide2=false;
            var cont=1;
            this.listFarm.forEach(element => {

                cont++;
                this.opSlFarm =this.farmidg;


            });
        }
        else
        {
          //"notiene asignado"
        }


      }
      else
      {
         this.formhide2=true;
         this.formhide=false;
      }
    }
    else
    {
      this.router.navigate(['login']);
    }
  }



}
capturar(value:any) {
  this.verSeleccion= value;
  this.listFarm.forEach(element => {
    if(this.verSeleccion==element.Value)
    {
      localStorage.setItem('farmID', element.Value);
      localStorage.setItem('nameFarm', element.Text);

      this.farmidg=element.Value;
    }
  });

  this.getParams();

 // if(this.Tit=="- Unidades Motrices")
 location.reload();

}
  ngOnInit() {
  }

  private getParams():void{
    this.api.getFromParams(this.farmidg).subscribe(response => {
      let dataResponse:paramsUM[]=response;
			if(response.error === undefined) {
      localStorage.setItem('paramsUM', JSON.stringify(dataResponse));
			} else {
				console.error("ERROR: The response had an error, retrying");
			}

		}, error => {
			console.error("ERROR: Unexpected response");
		});
   }

onLogout()
{
  localStorage.removeItem('usuario');
  localStorage.removeItem('paramsUM');
  localStorage.removeItem('nameFarm');
  localStorage.removeItem('paramsYieldTime');
  localStorage.removeItem('farmID');
  this.router.navigate(['login']);
  //localStorage.clear();
}

}
