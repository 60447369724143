<app-header [Tit]='nameItem'></app-header>


<img src="assets/loading.gif" [hidden]="load" class="loader" >
<div class="wrapper">
  <nav id="sidebar">
      <ul class="list-unstyled components small">
          <li>

             <div class="row small pb-1">
              <div class="row">
                <div class="col-12">
                  <h6>Maximo</h6>
                </div>
              </div>
              <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-6"><label>Hora: {{txtHoraMax}}</label></div>
               <div class="col-lg-6 col-md-6 col-sm-6"><label [ngStyle]="{color: (txtMaximo) != 'OK'  ? '#ff0000' : '#00aa00'}" >{{txtMaximo}}</label><label>:{{txtMaximo2 |number: '1.3'}}</label></div>
              </div>
              </div>

             <div class="row small pb-1">
              <div class="row">
                <div class="col-12">
                  <h6>Minimo</h6>
                </div>
              </div>
              <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-6"><label>Hora: {{txtHoraMin}}</label></div>
               <div class="col-lg-6 col-md-6 col-sm-6"><label [ngStyle]="{color: (txtMinimo) != 'OK'  ? '#ff0000' : '#00aa00'}">{{txtMinimo}}</label><label>:{{txtMinimo2 |number: '1.3'}}</label></div>
              </div>
              </div>
             <div class="row small pb-1">
              <div class="row">
                <div class="col-12">
                  <h6>Promedio</h6>
                </div>
              </div>
              <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-6"><label></label></div>
               <div class="col-lg-6 col-md-6 col-sm-6"><label [ngStyle]="{color: (txtPromedio) != 'OK' ? '#ff0000' : '#00aa00'}">{{txtPromedio}}</label><label>:{{txtPromedio2 |number: '1.3'}}</label></div>
             </div>
             </div>
             <div class="row small pb-1">
              <div class="col-md-6 col-sm-6"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(0,'')" style="font-size:x-small;">General</button></div>
            </div>

          </li>
      </ul>


  </nav>

  <div id="content" class="container-fluid">
    <div class="container small">
      <div class="row">
        <div class="col-12 small">
          <h3 style="color:#21618C;">{{txtnameFarm}}</h3>
        </div>
      </div>

          <div class="row p-2" >
            <div class="col-auto">
              <input [formControl]="dateStringControl" class="form-control" type="date" id="date-string" (change)="updateDate($event)" style="font-size:x-small;"/>
             </div>
             <div class="col-2">
              <input [ngxTimepicker]='picker' [formControl]="timeStringControl" class="form-control" placeholder="Hora Inicio" style="font-size:x-small;">
              <ngx-material-timepicker #picker></ngx-material-timepicker>
             </div>
             <div class="col-2">
              <input [ngxTimepicker]='picker2' [formControl]="timeStringControl2" class="form-control" placeholder="Hora Fin" style="font-size:x-small;">
              <ngx-material-timepicker #picker2></ngx-material-timepicker>
             </div>
             <div class="col-auto">
              <button type="button" class="btn btn-primary btn-sm" (click)="grafShare()" style="font-size:x-small;">Cargar</button>
             </div>
          </div>

      <div class="row p-2">
        <div class="col-11">
          <div class="tab-content" id="v-pills-tabContent">
              <div class="chart-container card card-body border-info" id="graf2" style="height:500px">
                <canvas id="realtime" width="1190" height="500"></canvas>
                <div class="card-content">

              </div>
            </div>
          </div>
        </div>



    </div>
          </div>
    </div>
</div>

<app-footer></app-footer>

