<app-header [Tit]='nameItem'></app-header>


<img src="assets/loading.gif" [hidden]="load" class="loader" >
<div class="wrapper">
  <nav id="sidebar">
      <ul class="list-unstyled components small">
          <li>
            <div class="row small">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <h6>Unidad Motriz:</h6>
                  </div>
                  <div class="col-6">
                    <div class="btn-group dropstart">
                     <select name="first" [(ngModel)]="selectUM" #t (change)="UMSelect(t.value)" class="btn btn-secondary dropdown-toggle" style="background-color: white; color:#21618C; margin-top: 0; margin-bottom: 0.5rem; font-weight: 500; line-height: 1.2;font-size: 1rem; font-size:x-small;">
                       <option *ngFor="let title of listUM" [value]="title.Value" class="btn btn-secondary dropdown-toggle " style="background-color: white; color:#21618C; font-size:x-small;">
                         {{title.Text}}
                       </option>
                   </select>
                  </div>
                 </div>
                </div>
              </div>
              <div class="col-6">
                <h6 [ngStyle]="{color: (txtFalla) == 'Correcto' ? '#00aa00' : '#ff0000'}">{{txtFalla}}</h6>
              </div>
             </div>
             <div class="row small pb-1">
               <div class="col-lg-4 col-md-6 col-sm-4"><label>Presion</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><label [ngStyle]="{color: (txtPresion) != 'OK'  ? '#ff0000' : '#00aa00'}" >{{txtPresion}}</label><label>{{txtPresion2}}</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(2,'Presion')" style="font-size:x-small;">Detalles</button></div>
             </div>

             <div class="row small pb-1">
               <div class="col-lg-4 col-md-6 col-sm-4"><label>Fuerza</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><label [ngStyle]="{color: (txtFuerza) != 'OK'  ? '#ff0000' : '#00aa00'}">{{txtFuerza}}</label><label>{{txtFuerza2}}</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(1,'Fuerza')" style="font-size:x-small;">Detalles</button></div>
             </div>
             <div class="row small pb-1">
               <div class="col-lg-4 col-md-6 col-sm-4"><label>Corriente</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><label [ngStyle]="{color: (txtCorriente) != 'OK' ? '#ff0000' : '#00aa00'}">{{txtCorriente}}</label><label>{{txtCorriente21}}</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(3,'Corriente')" style="font-size:x-small;">Detalles</button></div>
             </div>
             <div class="row small pb-1">
               <div class="col-lg-4 col-md-6 col-sm-4"><label>Corriente 2</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><label [ngStyle]="{color: (txtCorriente2) != 'OK' ? '#ff0000' : '#00aa00'}">{{txtCorriente2}}</label><label>{{txtCorriente22}}</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(4,'Corriente2')" style="font-size:x-small;">Detalles</button></div>
             </div>
             <div class="row small pb-1">
               <div class="col-lg-4 col-md-6 col-sm-4"><label>{{txtnumRueda}}</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><label [ngStyle]="{color: (txtRueda) != 'OK' ? '#ff0000' : '#00aa00'}" style="font-size:x-small;">{{txtRueda}}</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"></div>
             </div>
             <div class="row small pb-1">
               <div class="col-lg-4 col-md-6 col-sm-4"><label>{{txtnumGuarda}}</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"><label [ngStyle]="{color: (txtGuarda) != 'OK'? '#ff0000' : '#00aa00'}" style="font-size:x-small;">{{txtGuarda}}</label></div>
               <div class="col-lg-4 col-md-6 col-sm-4"></div>
             </div>
             <div class="row small pb-1">
               <div class="col-md-6 col-sm-6"><button type="button" class="btn btn-primary btn-sm" (click)="pushOne(0,'')" style="font-size:x-small;">General</button></div>
             </div>
          </li>
      </ul>
      <br/>
      <ul class="list-unstyled components small">
        <li [hidden]="EventsT">
          <div class="row small">
            <div class="col-12 small">

                  <label>{{dateEv}}</label>
                 <table class="table table-striped table-hover"  >
                   <thead>
                     <tr>
                       <th>Evento</th>
                       <th>1er Ordeña</th>
                       <th>2da Ordeña</th>
                       <th>3er Ordeña</th>
                     </tr>
                   </thead>
                    <tbody>
                     <tr *ngFor="let dat of cowsStuck; let i=index">
                       <td>{{ dat.Evento }}</td>
                       <td>{{ dat.Ordena1 }}</td>
                       <td>{{ dat.Ordena2 }}</td>
                       <td>{{ dat.Ordena3 }}</td>
                     </tr>
                   </tbody>
                 </table>

            </div>
          </div>
        </li>

      </ul>
  </nav>

  <div id="content" class="container-fluid">
    <div class="container small">
      <div class="row">
        <div class="col-12 small">
          <h3 style="color:#21618C;">{{txtnameFarm}}</h3>
        </div>
      </div>

          <div class="row p-2" >
            <div class="col-auto">
              <input [formControl]="dateStringControl" class="form-control" type="date" id="date-string" (change)="updateDate($event)" style="font-size:x-small;"/>
             </div>
             <div class="col-2">
              <input [ngxTimepicker]='picker' [formControl]="timeStringControl" class="form-control" placeholder="Hora Inicio" style="font-size:x-small;">
              <ngx-material-timepicker #picker></ngx-material-timepicker>
             </div>
             <div class="col-2">
              <input [ngxTimepicker]='picker2' [formControl]="timeStringControl2" class="form-control" placeholder="Hora Fin" style="font-size:x-small;">
              <ngx-material-timepicker #picker2></ngx-material-timepicker>
             </div>
             <div class="col-auto">
              <button type="button" class="btn btn-primary btn-sm" (click)="grafShare()" style="font-size:x-small;">Cargar</button>
             </div>
          </div>

      <div class="row p-2">
        <div class="col-11">
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
              <div class="chart-container card card-body border-info" id="graf2" style="height:500px">
                <canvas id="realtime" width="1190" height="500"></canvas>
                <div class="card-content">
                  <div class="row">
                    <div class="col-3"><label>{{titval}}</label></div>
                    <div class="col-3"><label></label></div>
                    <div class="col-3" [hidden]="hideminmax"><label>{{minmaxval}}</label></div>
                  </div>
              </div>
            </div>
            </div>
            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">

              <div class="card card-body border-info" id="tableData1" style="height:500px">
                <div class="row">
                  <div class="col-12">
                    <label class="small text-primary">Historial a 3 meses</label>
                  </div>
                </div>
                <div class="scrollbar scrollbar-primary" style="width:auto; height:auto">
        <div class="overflow-hidden small">
         <div class="small">
          <div class="card" id="navbar-example2">
            <div class="card-content px-3" >

              <table class="table table-striped table-hover" id="mytable" [hidden]="FailT1">
                <label [hidden]="hideFallas" class="text-primary">No hay Fallas.</label>
                <thead>
                  <tr>
                    <th name='inicio'>Fecha y Hora de la Falla</th>
                    <th name='falla'>Falla</th>
                    <th>Unidad Motriz</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let dat of fails; let i=index">
                    <td>{{ dat.Date }}</td>
                    <td>{{ dat.FailType }}</td>
                    <td>{{ dat.NumUM }}</td>
                  </tr>

                </tbody>
              </table>

              <table class="table table-striped table-hover" id="mytable1" [hidden]="FailT2">
                <label [hidden]="hideFallas" class="text-primary">No hay Fallas.</label>
                <thead>
                  <tr>
                    <th>Hora y Fecha de la Falla</th>
                    <th name='falla'>Falla</th>
                    <th>Unidad Motriz</th>

                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let dat of fails1; let i=index" >
                    <td>{{ dat.Date }}</td>
                    <td>{{ dat.Fail }}</td>
                    <td>{{ dat.UnidadM }}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
            </div>
        </div>
        </div>

              </div>

            </div>
          </div>
        </div>

        <div class="col-1">
          <div class="nav nav-pills p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <button class="nav-link active p-1" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><img src="assets/HS_ICON_GRAFB.png" style="height: 30px; width: 30px;"></button>
            <button class="nav-link p-1" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><img src="assets/HS_ICON_TABLA.png" style="height: 30px; width: 30px;"></button>
             </div>
        </div>

    </div>
          </div>
    </div>
</div>

<app-footer></app-footer>

