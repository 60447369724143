import { Component, OnInit } from '@angular/core';
import{ApiUMService}from '../servicios/apiUM.service';
import {Router}from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import moment from 'moment';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public mintmp0:any=0;
  public maxtmp0:any=0;

  public min0Val:any=0;
  public maxVal:any=0;

  public h1d:any='';
  public h1h:any='';
  public h2d:any='';
  public h2h:any='';
  public h3d:any='';
  public h3h:any='';


  public valor1:any="";
  public username:string="";
  public farmidg:any="";
  public farmName:any="";
  public farmNamestr:string="";
  public load=false;
  public nameItem="";
  public hidesuccess=true;
  public txtsucc='Se han guardado las configuraciones con exito';
  public params:any=[];


  constructor(private router:Router,private api:ApiUMService) {
    this.nameItem="- Settings"
   }

  ngOnInit(): void {
    let list:any="";
    let listUserFarm:any=[];
    let datosUser = JSON.parse(localStorage.getItem('usuario') || '0');

    datosUser.forEach((element:any) => {
      listUserFarm=element.DataUser;

     });
    if(listUserFarm!=null&&listUserFarm!=0)
    {

      this.username=listUserFarm.UserName;
      this.valor1=this.username;
      this.farmName = localStorage.getItem('nameFarm');
      this.farmidg = localStorage.getItem('farmID');

      this.searchparams();

    }
    else
    {
      this.router.navigate(['login']);
    }
  }


  searchparams()
  {
   this.load=true;
   let listres:any=[];
   let listresdata:any=[];
   var paramsdatafarm="";
   var paramsdatafarm2="";
   var cont=1;

   var time1:any='';
   var trimefin1:any='';
   var time2:any='';
   var trimefin2:any='';
   var time3:any='';
   var trimefin3:any='';


   paramsdatafarm= "yieldtime/"+this.farmidg;
   paramsdatafarm2= "settingstl/"+this.farmidg;



   this.api.getFromAPIgen(paramsdatafarm).subscribe(response => {
    this.load=false;
     if(response.error === undefined) {
      listres=response.data;

      listres.forEach((element:any) => {
        listresdata=element.data;

      });

      time1=moment(listresdata.time1).format('HH:mm:ss');
      trimefin1=moment(listresdata.finaltime1).format('HH:mm:ss');
      time2=moment(listresdata.time2).format('HH:mm:ss');
      trimefin2=moment(listresdata.finaltime2).format('HH:mm:ss');
      time3=moment(listresdata.time3).format('HH:mm:ss');
      trimefin3=moment(listresdata.finaltime3).format('HH:mm:ss');
      //let c=time1.format('HH:mm:ss');
      this.h1d=time1;
      this.h1h=trimefin1;
      this.h2d=time2;
      this.h2h=trimefin2;
      this.h3d=time3;
      this.h3h=trimefin3;



       this.load=true;

     }
     else
     {
      this.load=true;
     }
     //this.SpinnerService.hide();

   });

   this.api.getFromAPIgen(paramsdatafarm2).subscribe(response => {
    this.load=false;
    if(response.error === undefined) {
      listres=response.data;

     let listn:any=[];

     if(listres.length>0)
     {
      listres.forEach((element:any) => {
        listn=element.data;
        // this.min0Val

      });
     this.params=listn;

if(listn!=null&&listn!=undefined)
{
  if(listn.dataSilo.level!=undefined&&listn.dataSilo.level!=null)
  {
    this.min0Val=listn.dataSilo.level.min;
  }
  if(listn.dataSilo.level!=undefined&&listn.dataSilo.level!=null)
  {
    this.maxVal=listn.dataSilo.level.max;
  }

  if(listn.dataSilo.temp!=undefined&&listn.dataSilo.temp!=null)
  {
    this.mintmp0=listn.dataSilo.temp.min;
  }
  if(listn.dataSilo.temp!=undefined&&listn.dataSilo.temp!=null)
  {
    this.maxtmp0=listn.dataSilo.temp.max;
  }
}





     }



      this.load=true;

    }
    else
    {
      this.load=true;
    }
    //this.SpinnerService.hide();

  });

  }


  postSettings(num:any)
  {
   this.load=true;
   this.hidesuccess=true;

   let ent:any=[];

   if(num==1)
   {

    let datalis:any=[];
     datalis={
      time1:this.h1d,
      finaltime1:this.h1h,
      time2:this.h2d,
      finaltime2:this.h2h,
      time3:this.h3d,
      finaltime3:this.h3h,
    };

      ent= {
        farmid:this.farmidg,
        username:this.username,
        datainfo:datalis
        }

        this.api.postYieldSchedule(ent).subscribe(response => {
        let dataResponse:any=response;
        if(dataResponse!=null&&dataResponse!=undefined)
        {
          this.hidesuccess=false;
          this.txtsucc='Se han guardado los horarios de ordeño con exito';
        }
      });
   }
   else if(num==2)
   {


        if(this.params==undefined||this.params.length==0)
        {
          let listData:any=[];
          let listdataSilo:any=[];
          let listTemp:any=[];

          listdataSilo={temp:{min:this.mintmp0, max:this.maxtmp0},
          level:{min:this.min0Val, max:this.maxVal}};
          listData={dataSilo:listdataSilo};
          ent= {
            farmid:this.farmidg,
            username:this.username,
            num:1,
            datainfo:listData
          };

            this.api.postSettings(ent).subscribe(response => {
            let dataResponse:any=response;
            if(dataResponse!=null&&dataResponse!=undefined)
            {
              this.params=listdataSilo;
              this.hidesuccess=false;
              this.txtsucc='Se han guardado las configuraciones con exito';
            }
          });
        }
        else
        {
          let listData:any=[];
          let listdataSilo:any=[];

          listdataSilo={temp:{min:this.mintmp0, max:this.maxtmp0},
          level:{min:this.min0Val, max:this.maxVal}};
          listData={dataSilo:listdataSilo};
           ent= {
             farmid:this.farmidg,
             username:this.username,
             num:1,
             datainfo:listData
           };

             this.api.postSettings(ent).subscribe(response => {
             let dataResponse:any=response;
             if(dataResponse!=null&&dataResponse!=undefined)
             {
               this.params=listdataSilo;
               this.hidesuccess=false;
               this.txtsucc='Se han guardado las configuraciones con exito';
             }
           });


        }




   }

  }



}
