<nav class="navbar navbar-expand-lg" style="background-color: #21618C; ">
  <div class="container-fluid">
    <a class="navbar-brand" href="#" style="color: white;" routerLink="/dashboard">CONTROL MAESTRO{{Tit}}</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="40" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16" style="font-size: 2rem; color: white;">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">

      </ul>
      <form class="flex">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="btnUser" data-bs-toggle="dropdown" aria-expanded="false" style="margin-right: 100px;">
           {{valor1}}
          </button>
          <ul class="dropdown-menu" aria-labelledby="btnUser">
            <li><a class="dropdown-item" routerLink="/settings">Configuraciones</a></li>
            <li>
              <div class="btn-group dropstart">
              <select  [(ngModel)]="opSlFarm" #t (change)="capturar(t.value)" name="first" [hidden]="formhide2" class="btn btn-secondary dropdown-toggle" style="background-color: white; color:#21618C; margin-top: 0; margin-bottom: 0.5rem; font-weight: 500; line-height: 1.2;font-size: 1rem;">

                <option *ngFor="let title of listFarm" [value]="title.Value" class="btn btn-secondary dropdown-toggle ">
                  {{title.Text}}
                </option>
            </select>
          </div>
            </li>
            <li><a class="dropdown-item" id="btnLogOut" (click)="onLogout()">Cerrar Sesion</a></li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</nav>
