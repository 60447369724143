import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unidadesmotricesmobile',
  templateUrl: './unidadesmotricesmobile.component.html',
  styleUrls: ['./unidadesmotricesmobile.component.css']
})
export class UnidadesmotricesmobileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
