<app-header [Tit]='nameItem'></app-header>

<img src="assets/loading.gif" [hidden]="load" class="loader" >

<div class="wrapper">
  <nav id="sidebar">
      <ul class="list-unstyled components small">
          <li>
            <div class="row small">
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <h6>Lavado</h6>
                  </div>

                </div>
              </div>

             </div>
          </li>
      </ul>
      <br/>
      <ul class="list-unstyled components small">
          <li [hidden]="hidetabla">
            <div class="row small">
              <div class="col-12 small">

                    <label>{{dateEv}}</label>
                   <table class="table table-striped table-hover"  >
                     <thead>
                       <tr>
                        <th></th>
                         <th>Rango de Fecha</th>
                         <th>Promedio</th>
                       </tr>
                     </thead>
                      <tbody>
                       <tr>
                        <td>Promedio de Leche</td>
                         <td>{{ rangoprom }}</td>
                         <td>{{ promedio }}</td>
                       </tr>
                     </tbody>
                   </table>

              </div>
            </div>
          </li>

        </ul>
  </nav>

  <div id="content" class="container-fluid">
    <div class="container small">
      <div class="row">
        <div class="col-12 small">
          <h3 style="color:#21618C;">{{txtnameFarm}}</h3>
        </div>
      </div>

          <div class="row p-2" >
            <div class="col-auto">

            </div>
            <div class="col-auto">
              <input [formControl]="dateStringControl" class="form-control" type="date" id="date-string" style="font-size:x-small;"/>
             </div>
             <div class="col-auto">
              <input [formControl]="dateStringControl2" class="form-control" type="date" id="date-string" style="font-size:x-small;"/>
             </div>
             <div class="col-auto">
              <button type="button" class="btn btn-primary btn-sm" (click)="Share()" style="font-size:x-small;">Cargar</button>
             </div>
          </div>

      <div class="row p-2">
        <div class="col-11">
          <div class="card card-body border-info" id="tableData1" style="height:500px">
            <div class="scrollbar scrollbar-primary" style="width:auto; height:auto">
    <div class="overflow-hidden small">
     <div>
      <div class="card" id="navbar-example2">
        <div class="card-content px-3" >

              <table class="table table-striped table-hover" id="mytable">
                <label [hidden]="hideMsj" class="text-primary">No hay datos en este rango de fecha.</label>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Inicio</th>
                    <th>Fin</th>
                    <th>Lavado</th>
                    <th>Duracion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dat of listLav; let i=index" >
                    <td>{{ dat.Date }}</td>
                    <td>{{ dat.Inicio }}</td>
                    <td>{{ dat.Fin }}</td>
                    <td>{{ dat.Lavado }}</td>
                    <td>{{ dat.Duracion }}</td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>
     </div>
    </div>

          </div>
        </div>
        </div>
        <div class="col-1">

        </div>

    </div>
          </div>
    </div>
</div>

<app-footer></app-footer>

