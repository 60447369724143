import { Component } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Options } from 'selenium-webdriver';
import{ApiService}from '../app/servicios/api.service';
import { Token } from '../app/models/modelsLogin/Token';
import {Router}from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MControl';
 


  constructor(){ 

 
  }

 
}
