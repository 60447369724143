
<app-header [Tit]='nameItem'></app-header>
<img src="assets/loading.gif" [hidden]="load" class="loader" >

<div class="wrapper">
  <nav id="sidebar">
      <ul class="list-unstyled components small">
          <li>
            <div class="row small">
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <h6>Temperatura de Leche</h6>
                  </div>

                </div>
              </div>

             </div>
          </li>
      </ul>
      <br/>

  </nav>

  <div id="content" class="container-fluid">
    <div class="container small">
      <div class="row">
        <div class="col-12 small">
          <h3 style="color:#21618C;">{{txtnameFarm}}</h3>
        </div>
      </div>

          <div class="row p-2" >
            <div class="col-auto">
              <button type="button" class="btn btn-primary btn-sm" (click)="grafShare(1)">Hoy</button>
              <button type="button" class="btn btn-primary btn-sm" (click)="grafShare(2)">5D</button>
              <button type="button" class="btn btn-primary btn-sm" (click)="grafShare(3)">10D</button>
              <button type="button" class="btn btn-primary btn-sm" (click)="grafShare(4)">15D</button>
            </div>
            <div class="col-auto">
              <input [formControl]="dateStringControl" class="form-control" type="date" id="date-string" style="font-size:x-small;" [hidden]="hidebtndate"/>
             </div>
             <div class="col-auto">
              <input [formControl]="dateStringControl2" class="form-control" type="date" id="date-string" style="font-size:x-small;" [hidden]="hidebtndate"/>
             </div>
             <div class="col-auto">
              <button type="button" class="btn btn-primary btn-sm" (click)="grafShare(5)" style="font-size:x-small;" [hidden]="hidebtndate">Cargar</button>
             </div>
          </div>

      <div class="row p-2">
        <div class="col-11">
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
              <div class="chart-container card card-body border-info" id="graf2" style="height:500px">
                <canvas id="realtime" width="1190" height="500"></canvas>

            </div>
            </div>
            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">

              <div class="card card-body border-info" id="tableData1" style="height:500px">
                <div class="scrollbar scrollbar-primary" style="width:auto; height:auto">
        <div class="overflow-hidden small">
         <div class="small">
          <div class="card" id="navbar-example2">
            <div class="card-content px-3" >
              <table class="table table-striped table-hover" id="mytable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Ordeña</th>
                    <th>Inicio</th>
                    <th>Final</th>
                    <th>Diferencia</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let dat of listTable; let i=index">
                    <td>{{ dat.Date }}</td>
                    <td>{{ dat.Ordena }}</td>
                    <td>{{ dat.Inicio }} lts</td>
                    <td>{{ dat.Final }} lts </td>
                    <td>{{ dat.Diferencia }} lts</td>
                  </tr>

                </tbody>
              </table>


            </div>
          </div>
            </div>
        </div>
        </div>

              </div>

            </div>
          </div>
        </div>

        <div class="col-1">
          <div class="nav nav-pills p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <button class="nav-link active p-1" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><img src="assets/HS_ICON_GRAFB.png" style="height: 30px; width: 30px;"></button>
            <button class="nav-link p-1" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><img src="assets/HS_ICON_TABLA.png" style="height: 30px; width: 30px;"></button>
             </div>
        </div>

    </div>
          </div>
    </div>
</div>

<app-footer></app-footer>


