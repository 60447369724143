import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import $ from "jquery";

@Component({
  selector: 'app-listas',
  templateUrl: './listas.component.html',
  styleUrls: ['./listas.component.css']
})
export class ListasComponent implements OnInit {

  public list:string[]=[];
  @Input("stringToSubmit") stringToSubmit:string="";
  constructor(private route: ActivatedRoute, private router:Router) {


   }


  ngOnInit() {

    this.route.params.subscribe(params => {
      this.stringToSubmit = params.Tit;

      });

    if(this.stringToSubmit=="ML")
    {
      this.list=["-Tanques de Leche","-Placas Pre-enfriadoras","-Lavado","-Equipo de flujo de Leche"];
      this.stringToSubmit="Manejo de Leche";
    }
    else if(this.stringToSubmit=="RB")
    {
      this.list=["-MAX 1","-MAX 2","-MAX Light"];
      this.stringToSubmit="Robotica";
    }
    else if(this.stringToSubmit=="EN")
    {
      this.list=["-Compresores","-Presiones","-Niveles","-Chiller"];
      this.stringToSubmit="Enfriamineto";
    }
    else if(this.stringToSubmit=="ES")
    {
      this.list=["-Unidades Motrices","-Pulsalarm","-Nivel de vacio","-Arreador","-Valvula-flush","-Baños aereos","-Manejo y control de plataforma"];
      this.stringToSubmit="Estructura, Plataforma";
    }
    else if(this.stringToSubmit=="SC")
    {
      this.list=["-Sistema de afifarm","-Pesadores","-Afilab","-Readers","-Identificacion","-Bases de datos"];
      this.stringToSubmit="Sistema de Computo";
    }
    else if(this.stringToSubmit=="ME")
    {
      this.list=["-Valvulas flush","-Bombas agitadoras","-Separador de estiercol","-Biodigestor"];
      this.stringToSubmit="Manejo de estiercol";
    }
    else if(this.stringToSubmit=="MME")
    {
      this.list=["-Estacion de monitoreo de estres","-Baños","-Areas de enfriamineto"];
      this.stringToSubmit="Estacion de monitoreo de estres";
    }
  }

  pagShare(i:number) {

    if( $(document.getElementsByName('nameItem')))
    {

     var elements=$(document.getElementsByName('nameItem'));
     var name:any="";
     name=elements[i].textContent?.toString();

     if(name=="-Unidades Motrices")
     {
      this.router.navigate(['homeunidadesmotrices']);
     }
     else if(name=="-Lavado")
     {
      this.router.navigate(['lavadomob']);
     }


    }

   }



}
