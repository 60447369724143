import { Injectable } from '@angular/core';
import {LoginI} from '../models/modelsLogin/login.interface';
import {ResponseI}from '../models/modelsLogin/response.interface';
import{HttpClient, HttpHeaders}from '@angular/common/http';
import{Observable}from 'rxjs'
import {Token}from '../models/modelsLogin/Token'
//import { MessagesComponent } from '../messages/messages.component'



@Injectable({
  providedIn: 'root'
})
export class ApiService {



//private url='https://controlmaestroapi.xyz/control/api/userLogin';
//private urltoken='https://controlmaestroapi.xyz/control/api/token';

//private url: any ='http://10.10.10.3:3000/control/api/login';

private url: any ='http://45.15.24.217:3000/control/api/login';
private urltoken: any ='http://45.15.24.217:3000/control/api/token';
//private url='http://localhost:8051/control/api/userLogin';
//private urltoken='http://localhost:8051/control/api/token';

httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
constructor(private http:HttpClient) {

}

public loginByEmail1(form:LoginI):Observable<ResponseI>
{
  //return this.http.post<LoginI>(this.url,form);
  let direccion=this.url;
   return this.http.post<ResponseI>(direccion, form);

}

/** POST: add a new hero to the server */
public loginByEmail(form: any): Observable<any> {

  let direccion=this.url;
  return this.http.post<any>(direccion, form, this.httpOptions)

}

public tokenPost(list: Token): Observable<any> {

  let direccion=this.urltoken;
  return this.http.post<any>(direccion, list, this.httpOptions)

}

}
