
<app-header [Tit]='nameItem'></app-header>

<div class="container-fluid" >
<div class="container" >
  <img src="assets/loading.gif" [hidden]="load" class="loader" >


 <h3 style="color:#21618C;">{{farmName}}</h3>

 <div class="row">
  <div class="col-6">
    <div class="card card text-center">
      <div class="card-body">
        <h5 class="card-title">Nivel de Leche</h5>
        <div class="row text-center">
          <div class="col-auto">
            <img src="assets/silo.png"  alt="..." height="400px">
          </div>
          <div class="col-auto">
            <canvas id="grfSiloNvl" width="90" height="350"></canvas>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-12">
                <label class="card-text" [ngStyle]="{color: (nvlTexTst) != 'OK'  ? '#ff0000' : '#00aa00'}" >Estado:{{nvlTexTst}} {{nvlTextstatus}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Min: {{nvlText}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Max: {{nvlText2}}</label>
              </div>
            </div>

          </div>
         </div>
        <a class="btn btn-primary" (click)="redirect(1)">Ver mas</a>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="card card text-center">
      <div class="card-body">
        <h5 class="card-title">Temperatura de Leche</h5>

        <div class="row">
          <div class="col-auto">
            <img src="assets/silo.png"  alt="..." height="400px">
          </div>
          <div class="col-auto">
            <canvas id="grfSiloTemp" width="80" height="350"></canvas>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-12">
                <label class="card-text">Estado: {{tmpTextstatus}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Min: {{tmpText}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Max: {{tmpText2}}</label>
              </div>
            </div>

          </div>
         </div>
        <a class="btn btn-primary" (click)="redirect(2)">Ver mas</a>
      </div>
    </div>
  </div>
</div>

</div>
</div>

<app-footer></app-footer>
