import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{Router, Params,ParamMap}from '@angular/router'

import{Chart}from 'chart.js'
import { umData } from '../../models/modelsUM/dataUM';
import{ApiUMService}from '../../servicios/apiUM.service';
import { Observable, Subject, timer } from 'rxjs'
import { FormControl } from '@angular/forms';
import { formatNumber, Time } from '@angular/common';
import { paramsUM } from 'src/app/models/modelsUM/paramsUM';
import { failsUM } from 'src/app/models/modelsUM/failsUM';
import { failsTable } from 'src/app/models/modelsUM/failsTable';
import { ParsedEvent } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import {timeOut} from 'src/app/models/modelsUM/timeOut'
import { timeOutTable } from 'src/app/models/modelsUM/timeOutTable';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { ValueAndText } from 'src/app/models/ValueAndText';
import { valueUM } from 'src/app/models/valueUm';
import * as moment from 'moment';
import {failsTable1} from '../../models/modelsUM/failsTable1'

@Component({
  selector: 'app-nivelvacio',
  templateUrl: './nivelvacio.component.html',
  styleUrls: ['./nivelvacio.component.css']
})
export class NivelvacioComponent {

  /**
	* Interval to update the chart
	* @var {any} intervalUpdate
	*/
	private intervalUpdate: any = null;
	/**
	* The ChartJS Object
	* @var {any} chart
	*/

  public code0:string="";
  public code1:string="";
  public des0:string="";
  public txtMinI:any=0;
  public txtMaxI:any=0;
  public ScaleRight="";
  public ScaleLeft="";
  public alertHide=true;
  public load=false;
  public FailT1=true;
  public FailT2=true;
  public contfilltable:number=0;

  public hideminmax=true;

  public EventsT=false;
  public min0:any=0;
  public max0:any=0;
  public index0:number=0;
  public dateEv:any="";
  public hideFallas=true;
  public selectUM:any='1';
  public DateParos:string='';
  verSeleccion: string = '';
	public chart: any = null;
  public var:any=null;
  public num: number=0;
  public hour:any=null;
  public valfuerza: any = null;
  public valpresion: any = null;
  public valcorriente: any = null;
  public valcorriente2: any = null;
  public farmidg:any=0;
  public hourg:string="-1";
  public datesg:number=-1;
  public datefg:number=-1;
  public dateg:number=0;
  public username:string="";
  public valor1:any="";
  public titval:any="";
  date = new Date();
  nameindex:string="Establo ";
  public listUM:ValueAndText[]=[];
  dateStringControl = new FormControl('');
  timeStringControl = new FormControl('');
  timeStringControl2 = new FormControl('');
 // dateStringControl1 = new FormControl('');
  public fechastr:string="";
  public numval:number=0;
  public fechane:Date= new Date;
  public time:any="";
  public time2:any="";
  public minmaxval:string="";
 // public paramsUM1:any="";
  public minval:any="";
  public maxval:any="";
  public nomUM="1";
  fails: failsTable1[] = [];
  fails1: failsTable[] = [];
  cowsStuck: timeOutTable[] = [];
  public txtPromedio:any="";
  public txtMaximo:any="";
  public txtMinimo:any="";
  public txtHoraMax:any="";
  public txtHoraMin:any="";

  public txtPromedio2:any="";
  public txtMaximo2:any="";
  public txtMinimo2:any="";


  activate: Boolean = false;
  valUM:any=null;
  public txtFalla:string="";
  public nameItem="";
  //public load=false;
  public tableTO=true;
  public txtGuarda="";
  public txtRueda="";
  public txtnumGuarda="Guardamotor";
  public txtnumRueda="Rueda";
  public txtnameFarm:any="";
  public numUM:string="1";
  public dataUM:any[]=[];
  //public dataUM2:any[]=[];

  public datemes:any;


  constructor(private http: HttpClient,public api:ApiUMService, private router:Router) {
    //setTimeout(() => this.activate = true, 3000);

    let list:any="";
    this.nameItem="- Nivel de Vacio"
    this.txtFalla="Correcto";
    this.txtMaximo="-";
    this.txtMinimo="-";
    this.txtPromedio="-";
    this.numUM="1";
    //this.load=false;

    let datosUser = JSON.parse(localStorage.getItem('usuario') || '0');

    if(datosUser!=null&&datosUser!=0)
    {
      list=datosUser.UserFarm;
      this.username=datosUser.UserName;
      this.valor1=this.username;
      this.txtnameFarm = localStorage.getItem('nameFarm');
      this.farmidg = localStorage.getItem('farmID');
      var fecha = new Date(); //Fecha actual
      var mes = fecha.getMonth()+1; //obteniendo mes
      var dia = fecha.getDate(); //obteniendo dia
      var ano = fecha.getFullYear(); //obteniendo año
      var diastr="";
      var messtr="";


      if(dia<10)
      {
        diastr='0'+dia;
      }
      else
      {
        diastr=dia.toString();
      }
      if(mes<10)
      {
        messtr='0'+mes
      }
      else
      {
        messtr=mes.toString();
      }
        this.fechastr=ano+"-"+messtr+"-"+diastr;
        this.dateStringControl = new FormControl(this.fechastr);

        this.fechastr=this.dateStringControl.value;


    }
    else
    {
      this.router.navigate(['login']);
    }
  }
    /**
    * On component initialization
    * @function ngOnInit
    * @return {void}
    */

    private ngOnInit(num1:number): void {

      var date = new Date();
    var time1 = moment.duration("03:00:00");
    var date1 = moment(date)
    date1.subtract(time1);
    var cf=date1.format();


      this.hideFallas=true;
      if(this.listUM.length==0)
      {
        this.FillSelectUM();
      }

      this.CreateChart();



     if(num1==undefined)
     {
      var params = JSON.parse(localStorage.getItem('paramsUM') || '{}');
      if(params.length==0||params.length==undefined)
      {
       this.getParams();
      }

      this.fechane=this.dateStringControl.value;
      var f = moment(this.fechane);
      var fechaEv=moment(this.fechane);
      var dfEv=Date.parse(fechaEv.toString());
      var dfEv1=Date.parse(fechaEv.toString());
      //var datestr=moment(dfEv).format('LL');
      var datemes=Date.parse(fechaEv.toString());

      var datemesn=moment(datemes).subtract(3, 'months').format('YYYY-MM-DD');
      this.datemes=Date.parse(datemesn.toString());

      var datestr1=moment(dfEv1).subtract(1).format('LL');
      this.dateEv=datestr1;

      var dates_as_int = Date.parse(f.toString());
      var datenew=dates_as_int.toString();

      datenew=datenew.substring(0,10);
      let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 1;
      let suma =dates_as_int + semanaEnMilisegundos;
      //let fechaDentroDeUnaSemana = new Date(suma);


      //var newde=fechaDentroDeUnaSemana.toString();
      //var dates_as_intf = Date.parse(newde);
      this.showData(0,dates_as_int,suma, cf);

     }

      //this.intervalUpdate = setInterval(this.showData.bind(this), 150000);
    }

    CreateChart(){
      this.chart = new Chart('realtime', {
        type: 'line',
        data: {
          labels: [],
          datasets: [{
            label: 'Nivel de Vacio',
            fill: false,
            data: [],
            backgroundColor: '#1AAA08',
            borderColor: '#1AAA08',
            borderWidth : 3,
            pointRadius:0,
            }

          ]
          },
          options: {
            responsive:true,
            maintainAspectRatio:true,
          tooltips: {
            enabled: true,
            intersect: true
          },
          hover:{mode: 'index', intersect: false,},
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#00528b',
              boxWidth: 8,
              fontSize:10,
            },


          },
          scales: {
            yAxes: [{
              ticks: {
                fontColor: '#168ede',
                fontSize:10
              },

            }]
            ,
            xAxes: [{type: 'time',
            time: {
              unit: 'hour'
          },
          distribution: 'linear',
           ticks: {
            autoSkipPadding: 5,
            fontColor: '#168ede',
            maxTicksLimit:10,
            padding:0,
          fontSize:9,}}]
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'xy'
              },
              zoom: {
                enabled: true,
                mode: 'xy'
              }
            }
          }
          }
      });
    }

    public FillSelectUM():void{
      var cont=0;
      this.api.getNumUm(this.farmidg).subscribe(response => {

        let dataResponse:valueUM[]=response;


        if(response.error === undefined) {

          dataResponse.forEach(element => {
            this.listUM.push({Value:element.Numero,Text:element.Numero});
            if(cont==0)
            {
              this.selectUM=element.Numero;
            }
            cont=1;

          });
        } else {
          console.error("ERROR: The response had an error, retrying");
        }

      }, error => {
        console.error("ERROR: Unexpected response");
      });

    }

    private getParams():void{
      this.api.getFromParams(this.farmidg).subscribe(response => {
        let dataResponse:paramsUM[]=response;
        if(response.error === undefined) {
        localStorage.setItem('paramsUM', JSON.stringify(dataResponse));
        } else {
          console.error("ERROR: The response had an error, retrying");
        }

      }, error => {
        console.error("ERROR: Unexpected response");
      });
     }
    /**
    * Print the data to the chart
    * @function showData
    * @return {void}
    */
    private showData(num1:number, dateStart:number, dateFin:number,date1:any): void {


      this.numval=num1;
      var num:number;
      var paramsget="getNivelVacio/"+this.farmidg+"/"+dateStart+"/"+dateFin;





      //this.SpinnerService.show();
      this.load=false;

      this.api.getFromAPIgen(paramsget).subscribe(response => {

         this.dataUM=response;
        // this.dataUM2=response;

         if(this.dataUM.length>0)
         {
          let lastItem = response.pop();
          var letdate=lastItem.date;
          console.log(`Last element is ${letdate}`);

          if(response.error === undefined) {

            // console.log(date1);



           var arrPorID:any[]=[];
           //var arrPorID1:any[]=[];
           //var arrPorIDPO:any[]=[];

           //arrPorIDPO=this.dataUM;

            num=this.dataUM.length;
            var valpresion="";
            var valMax:any;
            var valMin:any;
            var valProm:any;
            let total = 0;


            var lenghmaxmin=0;
            lenghmaxmin=this.dataUM.length;

            if(lastItem!=undefined)
            {
             if(num1==0)
             {
               function filtrarPorID(obj:any)
               {
                 if ('date' in obj && obj.date >= letdate) {
                   return true;
                 } else {
                   return false;
                 }
               }
                arrPorID = this.dataUM.filter(filtrarPorID);
              // console.log( arrPorID);

              //arrPorID1=arrPorID;

               arrPorID.forEach(element => {

                 //valpresion=element.nivelvacio.toString();

                 var dateNew=moment(element.date).format("YYYY-MM-DD HH:mm:ss");
                  this.chart.data.labels.push(dateNew);

                  this.chart.data.datasets[0].data.push(element.nivelvacio);

                  this.chart.update();


              });

              if(this.dataUM.length>0)
              {

                function MyMax(myarr:any){
                  var al = myarr.length;
                  valMax = myarr[al-1];
                  valMin=myarr[al-1];
                 var nuevo;
                  while (al--){
                    nuevo=myarr[al];
                    total=total+nuevo.nivelvacio;
                      if(nuevo.nivelvacio > valMax.nivelvacio){
                        valMax = myarr[al]
                      }
                      if(nuevo.nivelvacio < valMin.nivelvacio){
                        valMin = myarr[al]
                    }
                  }
                  console.log(valMax)
                  console.log(valMin)
                       //   return valMax;
              };
             MyMax(this.dataUM);



               if(total!=null&& total!=undefined)
               {
                valProm = total / lenghmaxmin;
               }


                var valMaxfin="";
                var valMinfin="";


                console.log(valProm)

                this.txtMinimo="OK";
                this.txtMinimo2=valMin.nivelvacio;
                this.txtHoraMin=moment.utc(valMin.date).format("HH:mm:ss");

                this.txtMaximo="OK";
                this.txtMaximo2=valMax.nivelvacio;
                this.txtHoraMax=moment.utc(valMax.date).format("HH:mm:ss");

                this.txtPromedio="OK";
                this.txtPromedio2=valProm;

              }

             }
             else
             {
               this.UpdateChart2(this.dataUM,this.titval,num1);
             }
            }


             }
             //this.SpinnerService.hide();

         }



      });


      this.load=true;

    }

    public pushOne(num:number,name:string): void {


       this.UpdateChart2(this.dataUM,name, num);
    }

    updateDate(event: any) {

      //this.fails=[];
      this.cowsStuck=[];
     this.txtMaximo="-";
     this.txtMinimo="-";
     this.txtPromedio="-";
     this.timeStringControl = new FormControl("");
     this.timeStringControl2 = new FormControl("");

     this.fechane=this.dateStringControl.value;
     var f = moment(this.fechane);
     var fechaEv=moment(this.fechane);
     var fdEv= Date.parse(fechaEv.toString());

     //var datestr=moment(fdEv).format('LL');
     //this.dateEv=datestr;

     var dfEv1=Date.parse(fechaEv.toString());
     //var datestr=moment(dfEv).format('LL');

     var datestr1=moment(dfEv1).subtract(1).format('LL');
     this.dateEv=datestr1;

     var dates_as_int = Date.parse(f.toString());
     var datenew=dates_as_int.toString();

     datenew=datenew.substring(0,10);
     let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 1;
     let suma =dates_as_int + semanaEnMilisegundos;
     let fechaDentroDeUnaSemana = new Date(suma);


     var newde=fechaDentroDeUnaSemana.toString();
     var dates_as_intf = Date.parse(newde);

      this.chart.clear();
      this.chart.destroy();

      //this.ngOnInit(0);
      this.CreateChart();

     // var date = new Date();
     // var time1 = moment.duration("03:00:00");
      //var date1 = moment(dates_as_intf)
      //date1.subtract(time1);

     // var date = new Date();

     var date = new Date();
     var dateform=moment(date).format("YYYY-MM-DD");

     var time1;
     var date1;;
     var cf:any;

     if(this.fechane.toString()==dateform)
     {
        time1 = moment.duration("03:00:00");
        date1 = moment(date)
        date1.subtract(time1);
        cf=date1.format();
     }
     else
     {
       time1 = moment.duration("03:00:00");
       date1 = moment(suma)
       date1.subtract(time1);
       cf=date1.format();
     }

     // console.log(dates_as_intf);
      //console.log(date1);

      this.showData(0,dates_as_int,dates_as_intf,cf);

     // this.showData(0,fechanuevo,fechaFinnuevo,cf);

    }
    grafShare() {

      var valMax:any;
      var valMin:any;
      var valProm:any;
      let total = 0;


      var lenghmaxmin=0;


      var arrPorID:any[]=[];
      this.fechane=this.dateStringControl.value;
      var f = moment(this.fechane);
      this.time=this.timeStringControl.value;

      this.time2=this.timeStringControl2.value;

      if(this.time==""&&this.time2!="")
      {
       alert("Elija una hora de Inicio");

      }
      else if(this.time!=""&&this.time2=="")
      {
        alert("Elija una hora Final");
      }
      else
      {


        this.fechane=this.dateStringControl.value;
        var f = moment(this.fechane);
        var ffin=moment(this.fechane).add(1,"days");



        this.time=this.timeStringControl.value;
        this.time2=this.timeStringControl2.value;
        var dates_as_int:any;
        var suma:any;

        let fatenew:any;
        let fatenew2:any;

          if(this.time==""&&this.time2=="")
          {

             fatenew=f;
             fatenew2=ffin;

          }
          else
          {

              fatenew=this.fechane+" "+this.time;
              fatenew2=this.fechane+" "+this.time2;

          }

            let fecha = new Date(fatenew);
            let fecha2 = new Date(fatenew2);

            var f = moment(fecha);
            var fn=f.format("YYYY-MM-DD HH:mm:ss");
            var f2 = moment(fecha2);
            var fn2=f2.format("YYYY-MM-DD HH:mm:ss");

            var gt=new Date();


            function filtrarPorID(obj:any)
            {
               gt=new Date(obj.date);
                var c=moment.utc(obj.date);
              if ('date' in obj && moment.utc(obj.date).format("YYYY-MM-DD HH:mm:ss") >= fn && moment.utc(obj.date).format("YYYY-MM-DD HH:mm:ss") <= fn2) {
                return true;
              } else {
                return false;
              }
            }

           arrPorID = this.dataUM.filter(filtrarPorID);

           console.log(arrPorID);
           lenghmaxmin=arrPorID.length;

           if(arrPorID.length>0)
           {

             function MyMax(myarr:any){
               var al = myarr.length;
               valMax = myarr[al-1];
               valMin=myarr[al-1];
              var nuevo;
               while (al--){
                 nuevo=myarr[al];
                 total=total+nuevo.nivelvacio;
                   if(nuevo.nivelvacio > valMax.nivelvacio){
                     valMax = myarr[al]
                   }
                   if(nuevo.nivelvacio < valMin.nivelvacio){
                     valMin = myarr[al]
                 }
               }
               console.log(valMax)
               console.log(valMin)
                    //   return valMax;
           };
          MyMax(arrPorID);


          if(total!=null&& total!=undefined)
          {
           valProm = total / lenghmaxmin;
          }

             console.log(valProm)

                this.txtMinimo="OK";
                this.txtMinimo2=valMin.nivelvacio;
                this.txtHoraMin=moment.utc(valMin.date).format("HH:mm:ss");

                this.txtMaximo="OK";
                this.txtMaximo2=valMax.nivelvacio;
                this.txtHoraMax=moment.utc(valMax.date).format("HH:mm:ss");;

          this.txtPromedio="OK";
          this.txtPromedio2=valProm;
           }

           this.chart.destroy();

           this.UpdateChart2(arrPorID,"", 0);



      }

    }

    UMSelect(value:any)
    {


      this.txtMinimo="-";
      this.txtMaximo="-";
      this.txtPromedio="-";

       this.fails=[];
       this.fails1=[];
       this.contfilltable=0;
     this.cowsStuck=[];



      this.chart.clear();
      this.chart.destroy();
      this.verSeleccion= value;
      this.fechane=this.dateStringControl.value;
      var f = moment(this.fechane);

      var fechaEv=moment(this.fechane);
      var fdEv= Date.parse(fechaEv.toString());

      var dfEv1=Date.parse(fechaEv.toString());

      var datestr1=moment(dfEv1).subtract(1).format('LL');
      this.dateEv=datestr1;


      this.time=this.timeStringControl.value;
      this.time2=this.timeStringControl2.value;
      var dates_as_int:any;
      var suma:any;

      if(this.time==""&&this.time2=="")
      {

       dates_as_int = Date.parse(f.toString());
       var datenew=dates_as_int.toString();

       datenew=datenew.substring(0,10);
       let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 1;
       suma =dates_as_int + semanaEnMilisegundos;
       let fechaDentroDeUnaSemana = new Date(suma);
      }
      else
      {
         let fatenew=this.fechane+" "+this.time;
         let fatenew2=this.fechane+" "+this.time2;
         let fecha = new Date(fatenew);
         let fecha2 = new Date(fatenew2);
         var f = moment(fecha);
         var f2 = moment(fecha2);

         var fechaEv=moment(fecha);
         var fdEv= Date.parse(fechaEv.toString());
         var dfEv1=Date.parse(fechaEv.toString());

         var datestr1=moment(dfEv1).subtract(1).format('LL');
         this.dateEv=datestr1;

         var time_as_int = Date.parse(f.toString());
         dates_as_int=time_as_int-18000000;
         var time_as_int2 = Date.parse(f2.toString());
         suma=time_as_int2-18000000;
      }

      var date = new Date();
      var dateform=moment(date).format("YYYY-MM-DD");

      var time1;
      var date1;;
      var cf:any;





          this.CreateChart();
          this.showData(0,dates_as_int,suma,dates_as_int);

     }

   UpdateChart2(arrPorID:any[], name:string, num:number){


    var datenew;
  // console.log(arrPorID);
      this.chart.destroy();
      var color=this.Color(num);
       if(num==0)
       {
        this.hideminmax=true;
        this.titval="General";
        this.CreateChart();
       }





      arrPorID.forEach(element => {

        datenew=moment.utc(element.date).format("YYYY-MM-DD HH:mm:ss");



          this.chart.data.labels.push(datenew);
          this.chart.data.datasets[0].data.push(element.nivelvacio);


      });


      this.chart.update();

     }


  private Color(index:number):any{

    var color="";
    switch (index) {
      case 1:
        color="#1AAA08";
        break;
        case 2:
        color="#0A4AB6";
        break;
        case 3:
        color="#D35400";
        break;
        case 4:
        color="#F5B041";
        break;

    }
    return color;

  }

  updateScale()
  {
   this.chart.options = {
    scales: {
      yAxes: [{
        type: 'linear',
        position: 'left' ,
        display: true,
        gridLines: { display: true},
         ticks: { min:this.min0,max:this.max0,
          fontColor: '#168ede',
          maxTicksLimit: 6,
          padding: 0,
          fontSize:10},
           scaleLabel: { display: true,
            labelString: this.des0,fontColor: '#00528b',lineHeight: 0,padding: 0,
            fontSize:10}
      }]
  },
    responsive: true,
    maintainAspectRatio: false,
     layout: {
        padding: {
          left: 10,
          right: 10,
           top: 10,
            bottom: 10 } },
            tooltips:{ mode: 'point',
            intersect: true, backgroundColor: 'rgba(0, 0, 0, 0.6)'},
            hover: { mode: 'index', intersect: false, },
            legend: { position: 'bottom',
             labels: { fontSize: 9,
                boxWidth: 8, }, display: true,},
                elements: { line: { tension: 0.3 } },
  };
  }

    onLogout()
    {

      localStorage.removeItem('usuario');
      localStorage.removeItem('paramsUM');
      this.router.navigate(['login']);
      //localStorage.clear();
    }

}
