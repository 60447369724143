import { Injectable } from '@angular/core';
import{HttpClient, HttpHeaders}from '@angular/common/http';
import{Observable}from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class ApiUMService {


 //private url='https://controlmaestroapi.xyz/control/api/';
 private url: any ='http://45.15.24.217:3000/control/api/';
 //private url: any ='http://10.10.10.3:3000/control/api/';
  //private url='http://localhost:8051/control/api/';


  httpOptions = {
    headers: new HttpHeaders({'Content-Type':'application/json; charset=utf-8'})
  };



  constructor(private http:HttpClient) {

  }
  public getFromAPI(dates_as_int:any,dates_as_intf:any,farmidg:number, num:string): Observable<any>{

	  return this.http.get(	this.url+ "getUMData/"+farmidg+"/"+dates_as_int+"/"+dates_as_intf+"/"+num,
		{ responseType: 'json' }
	  );

	}
  public getFromAPIgen(params:any): Observable<any>{

	  return this.http.get(	this.url+params,
		{ responseType: 'json' }
	  );

	}
  public getNumUm(farmidg:string): Observable<any>{

	  return this.http.get(this.url+"getNumUM/"+farmidg,
		{ responseType: 'json' }
	  );

	}

  public getFromParams(farmidg:number): Observable<any>{

	  return this.http.get(this.url+"getMinMaxFarms/"+farmidg,
		{ responseType: 'json' }
	  );

	}
  public getFailsUM(dates_as_int:any,datesfin:any,farmidg:number, num:number): Observable<any>{

	  return this.http.get(this.url+"getFailsUM/"+farmidg+"/"+dates_as_int+"/"+datesfin+"/"+num,
		{ responseType: 'json' }
	  );

	}
	public getFailsTable(dates_as_int:any,datesfin:any,farmidg:number): Observable<any>{

		return this.http.get(this.url+"getFailsUMTable/"+farmidg+"/"+dates_as_int+"/"+datesfin,
		  { responseType: 'json' }
		);

	  }
  public getEventsUM(dates_as_int:any,farmidg:number): Observable<any>{

	  return this.http.get(this.url+"getEventsUM/"+farmidg+"/"+dates_as_int,
		{ responseType: 'json' }
	  );

	}

  public postSettings(indicator: any): Observable<any> {

    let direccion=this.url+'settingstl';
    return this.http.post<any>(direccion, indicator, this.httpOptions)

  }
  public postYieldSchedule(indicator: any): Observable<any> {

    let direccion=this.url+'yieldtime';
    return this.http.post<any>(direccion, indicator, this.httpOptions)

  }


}
