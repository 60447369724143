import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from 'ng2-charts';
import { HeaderComponent } from './plantillas/header/header.component';
import { FooterComponent } from './plantillas/footer/footer.component';
import { CardUMComponent } from './plantillas/cards/cardUM/cardUM.component';
import {CommonModule}from '@angular/common'
import{ReactiveFormsModule, FormsModule} from '@angular/forms'
import{HttpClientModule}from '@angular/common/http';
import { MessagesComponent } from './messages/messages.component';
import { StyleDirective } from './directives/style.directive';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker'
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import 'hammerjs';
import 'chartjs-plugin-zoom';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
      MessagesComponent,
      HeaderComponent,
      FooterComponent,
      routingComponents,
      StyleDirective,
      CardUMComponent,
      
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    CommonModule,
    NgxMaterialTimepickerModule,
    BrowserAnimationsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
