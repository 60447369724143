import { Component, OnInit } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Options } from 'selenium-webdriver';
import{ApiService}from '../../servicios/api.service';
import { Token } from '../../models/modelsLogin/Token';
import {Router}from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ApiUMService} from '../../servicios/apiUM.service'

import{Chart}from 'chart.js'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public readonly VAPID_PUBLIC_KEY='BE4y65zvekX0oTF8d1az6yrd1Tl2VHcIvLUgUG7d0qw7jSJwZyZn3hxVv0Czc8I5pZc3Q7UoKgf5g4ycHrKN5WQ';
  public valor1:any="";
  public username:string="";
  public farmidg:any="";
  public farmName:any="";
  public farmNamestr:string="";
  public nameTit="";
  public nameTit2="";
  public nameTit3="";
  public nameTit4="";
  public nameTit5="";
  public nameTit6="";
  public nameTit7="";
  public load=false;
  public dataUM:any[]=[];
  public chart: any = null;
  public chart2: any = null;
  public tmpText:any='';
  public tmpText2:any='';
  public tmpTextstatus:any='';
  public nvlText:any='';
  public nvlText2:any='';
  public nvlTextstatus:any='';
  public nvlTexTst:any='';
  public nameItem="";
 // public txtnameFarm="";

  constructor(private router:Router,private swPush:SwPush,private api:ApiUMService, private apiUM:ApiUMService) {

    setTimeout("location.reload()", 900000);
    this.nameTit="ML"
    this.nameTit2="RB"
    this.nameTit3="EN"
    this.nameTit4="ES"
    this.nameTit5="SC"
    this.nameTit6="ME"
    this.nameTit7="MME"

    this.nameItem="- Tanques de Leche"
    let list:any="";
    let listUserFarm:any=[];
    let datosUser = JSON.parse(localStorage.getItem('usuario') || '0');

    datosUser.forEach((element:any) => {
      listUserFarm=element.DataUser;

     });
    if(listUserFarm!=null&&listUserFarm!=0)
    {

     // list=datosUser.UserFarm;
      this.username=listUserFarm.UserName;
      this.valor1=this.username;
      this.farmName = localStorage.getItem('nameFarm');
      this.farmidg = localStorage.getItem('farmID');

    }
    else
    {
      this.router.navigate(['login']);
    }

   }

  ngOnInit() {

    //var params = JSON.parse(localStorage.getItem('paramsYieldTime') || '{}');
    //if(params.length==0||params.length==undefined)
   // {
    // this.paramYield();
    // params = JSON.parse(localStorage.getItem('paramsYieldTime') || '{}');
    //}
this.CreateChart();
this.getdataLevel();
this.CreateChartTemp();
this.load=true;
  }


  paramYield() {

    var paramsdatafarm="";
    paramsdatafarm= "getYieltime/"+this.farmidg;

    this.load=false;

		this.apiUM.getFromAPIgen(paramsdatafarm).subscribe(response => {

			if(response.error === undefined) {
        this.dataUM=response;

        if(this.dataUM.length>0)
        {
          this.dataUM.forEach(element => { localStorage.setItem('paramsYieldTime', JSON.stringify(this.dataUM));
          });
        }
        else{


        }


			}
      //this.SpinnerService.hide();
      this.load=true;
    });

  }

  CreateChart(){
		this.chart = new Chart('grfSiloNvl', {
			type: 'bar',
			data: {
				labels: [],
				datasets: [{
          label: 'Nivel',
          fill: false,
          data: [],
          backgroundColor: '#1AAA08',
          borderColor: '#1AAA08',
          borderWidth : 3,
          pointRadius:0,
          },

				]
			  },
        options:
        {
          legend:{
            display:false
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {padding:{left: 0,right: 0,top: 0,bottom: 0}},
          scales: {
            yAxes: [{
                type: 'linear',
                position: 'right',
                ticks:
                {
                  min: 0,
                  max: 80000
                }

            }],

        }
        }
		});
  }
  CreateChartTemp(){
		this.chart2 = new Chart('grfSiloTemp', {
			type: 'bar',
			data: {
				labels: [],
				datasets: [{
          label: 'Nivel',
          fill: false,
          data: [2],
          backgroundColor: '#1AAA08',
          borderColor: '#1AAA08',
          borderWidth : 3,
          pointRadius:0,
          },

				]
			  },
        options:
        {
          legend:{
            display:false
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {padding:{left: 0,right: 0,top: 0,bottom: 0}},
          scales: {
            yAxes: [{
                type: 'linear',
                position: 'right',
                ticks:
                {
                  min: 0,
                  max: 5
                }

            }],

        }
        }
		});
  }

  redirect(num:any)
  {
    if(num==1)
    {
      this.router.navigate(['tanquesleche']);
    }
    else
    {
      this.router.navigate(['milktemp']);
    }
  }


  onLoadCM(num:number)
  {
   if(num==1)
   {
    this.router.navigate(['unidadesmotrices']);
   }
  }

  private getdataLevel(): void {

    var params="";
    var num:number;
    var contyd=0;



    var paramsdatatep:any=[];
    var paramsdatafarm="";
    paramsdatafarm= "settingstl/"+this.farmidg;

    this.load=false;
    let datatemp:any=[];
    let listn:any=[];

    this.api.getFromAPIgen(paramsdatafarm).subscribe(response => {

			if(response.error === undefined) {
        paramsdatatep=response.data;

        if(paramsdatatep.length>0)
        {
          paramsdatatep.forEach((element:any) => {
           listn=element.data;
           // this.min0Val

         });

         if(listn!=null&&listn!=undefined)
         {
           if(listn.dataSilo.level!=undefined&&listn.dataSilo.level!=null)
           {
             this.nvlText=listn.dataSilo.level.min;
           }
           if(listn.dataSilo.level!=undefined&&listn.dataSilo.level!=null)
           {
             this.nvlText2=listn.dataSilo.level.max;
           }

           if(listn.dataSilo.temp!=undefined&&listn.dataSilo.temp!=null)
           {
             this.tmpText=listn.dataSilo.temp.min;
           }
           if(listn.dataSilo.temp!=undefined&&listn.dataSilo.temp!=null)
           {
             this.tmpText2=listn.dataSilo.temp.max;
           }
         }
        }



        params= "/umdata/umdata/getLastMilkLevel/"+this.farmidg;



        this.api.getFromAPIgen(params).subscribe(response => {
          datatemp=response.data;

           var data:any='';
          // data=datatemp.data;

           datatemp.forEach((element:any) => {
                data=element.data;
           });

           if(data!=undefined)
           {
            this.UpdateChart(data.milklevel);

            if(this.nvlText!=undefined&&this.nvlText!=null&&this.nvlText2!=undefined&&this.nvlText2!=null)
            {
                    if(data.milklevel>this.nvlText&&data.milklevel<this.nvlText2)
                    {
                          this.nvlTextstatus=data.milklevel;
                          this.nvlTexTst='OK';
                    }
                    else
                    {
                      this.nvlTextstatus='ALERTA: '+data.milklevel;
                    }
            }
           }




                //this.SpinnerService.hide();
                this.load=true;
              });



			}

      //this.load=true;
    });





	}
  private getdataTemp(): void {

    var params="";
    var num:number;
    var contyd=0;



    var paramsdatatep:any=[];
    var paramsdatafarm="";
    paramsdatafarm= "settingsLevel/"+this.farmidg;

    this.load=false;

    this.api.getFromAPIgen(paramsdatafarm).subscribe(response => {

			if(response.error === undefined) {
        paramsdatatep=response.data;

        let listn:any=[];

        if(paramsdatatep.length>0)
        {
          paramsdatatep.forEach((element:any) => {
           listn=element.data;
           // this.min0Val

         });

         if(listn!=null&&listn!=undefined)
         {
           if(listn.dataSilo.level!=undefined&&listn.dataSilo.level!=null)
           {
             this.nvlText=listn.dataSilo.level.min;
           }
           if(listn.dataSilo.level!=undefined&&listn.dataSilo.level!=null)
           {
             this.nvlText=listn.dataSilo.level.max;
           }

           if(listn.dataSilo.temp!=undefined&&listn.dataSilo.temp!=null)
           {
             this.tmpText=listn.dataSilo.temp.min;
           }
           if(listn.dataSilo.temp!=undefined&&listn.dataSilo.temp!=null)
           {
             this.tmpText=listn.dataSilo.temp.max;
           }
         }
        }




        params= "/umdata/umdata/getLastMilkLevel/"+this.farmidg;



        this.api.getFromAPIgen(params).subscribe(response => {
          this.dataUM=response.data;




                //this.SpinnerService.hide();
                this.load=true;
              });



			}

      //this.load=true;
    });





	}

  UpdateChart(val:any){

    this.chart.data.labels.push('Nivel');
    this.chart.data.datasets.forEach((dataset:any) => {
        dataset.data.push(val);
    });
    this.chart.update();
   // this.chart.data.datasets[0].data.push(val);
    //this.chart.update();

   }


}
