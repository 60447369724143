import { Component, OnInit } from '@angular/core';
import{FormGroup, FormControl,Validators}from '@angular/forms'
import{ApiService}from '../../servicios/api.service';
import{LoginI}from '../../models/modelsLogin/login.interface';

import{ResponseI}from '../../models/modelsLogin/response.interface';
import {Router}from '@angular/router';
import { ValueAndText } from 'src/app/models/ValueAndText';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public load=false;
  public formhide=false;
  public formhide2=true;
  public slFarm:ValueAndText[]=[];
  public opSlFarm: string  = '0';
  public valor1:any="";
  public username:string="";
  public farmidg:any="";
  public farmName:any="";
  public farmNamestr:string="";

  verSeleccion: string = '';
  loginForm=new FormGroup({
    userName:new FormControl('',Validators.required),
    password: new FormControl('',Validators.required)
  });
  constructor(private api:ApiService, private router:Router) {
    let listUserFarm:any=[];
    let datosUser = JSON.parse(localStorage.getItem('usuario') || '0');

    if(datosUser==0)
    {
      this.router.navigate(['login']);
    }
    else
    {
      datosUser.forEach((element:any) => {
        listUserFarm=element.DataUser;

       });
      if(listUserFarm!=null&&listUserFarm!=0)
      {

        this.router.navigate(['dashboard']);
      }
      else
      {
        this.router.navigate(['login']);
      }
    }

  }

  errorStatus:boolean=false;
  errorMsj:any="";
  datosUser:any="";

  ngOnInit(): void {
  }

  onLogin(form:LoginI){

    if(form.userName==""||form.password=="")
    {
      this.errorStatus=false;
      this.errorMsj="Campos Vacios";
    }
    else
    {
      this.errorStatus=true;
      this.errorMsj="";
      try{
        this.api.loginByEmail(form).subscribe(data=>{
          let dataResponse:any=data.data;
          let listUserFarm:any=[];
          let list:any[]=[];

          dataResponse.forEach((element:any) => {
            listUserFarm=element.DataFarm;

           });

          if(listUserFarm.length!=0)
          {

            listUserFarm.forEach((element:any) => {
           list.push({Value:(element.FarmID).toString(),Text:element.NameFarm});

            });

            if(list.length>0)
            {
              if(list.length==1)
              {
                this.formhide=false;
                this.formhide2=true;
                list.forEach(element => {
                  localStorage.setItem('farmID', element.Value);
                  localStorage.setItem('nameFarm', element.Text);
                });
                this.router.navigate(['dashboard']);

              }
              else
              {
                this.slFarm = (list as any);

                this.formhide=true;
                this.formhide2=false;
                var cont=1;
                list.forEach(element => {
                  if(cont==1)
                  {
                    cont++;
                    this.opSlFarm =element.Value;
                  }

                });

              }
              localStorage.setItem('usuario', JSON.stringify(dataResponse));
            }
            else
            {
              //"notiene asignado"
            }

          }
          else
          {
            this.errorStatus=false;
            this.errorMsj="Error en el usuario y/o contraseña";
          }

        });
      }
      catch(Ex)
      {
        this.errorStatus=false;
        this.errorMsj="Error en el usuario y/o contraseña";
      }

    }



}
capturar(value:any) {
  this.verSeleccion= value;
}
 onLogFarm()
 {

  this.slFarm.forEach(element => {

    if(this.verSeleccion=="")
    {
      this.verSeleccion= element.Value;
    }
    if(this.verSeleccion==element.Value)
    {
      localStorage.setItem('farmID', element.Value);
      localStorage.setItem('nameFarm', element.Text);
    }

  });

  this.router.navigate(['dashboard']);
 }
}
