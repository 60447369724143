<app-header></app-header>

<div class="container-fluid">
  <div class="row pb-3">
    <div class="col-12">
      <h5>{{stringToSubmit}}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
      <table class="table table-striped table-hover">
        <tbody>
          <tr *ngFor="let item of list let i=index" >
            <td (click)="pagShare(i)" name="nameItem">{{item}}</td>
          </tr>
        </tbody>
      </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button type="button" class="btn btn-primary btn-sm" style="font-size:x-small;" routerLink="/dashboard">Regresar</button>
    </div>
  </div>
</div>
